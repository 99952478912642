import React, { useState } from 'react'
import { ArrowRightInputVendor } from '../../../../../../../../../Vendor/pages/registerVendor/sections/formAfterSelectRole/icons'
import GetIndonesianAddress from '../../../../../../../../../GlobalComponent/GetAddress'
import GetCoordinate from '../../../../../../../../../GlobalComponent/getCoordinate'
import LoadingSpinner from '../../../../../../../../../Vendor/Components/loadingSpinner';
import { googleMapsIcon } from '../../../../../../../../../konsumen/assets/images';
import { useAppDispatch, useTypedSelector } from '../../../../../../../../../redux/store';
import { setDataAdmin1, setStep, setId } from '../../../../../../../../../redux/store/reducers/Produsen/Auth/Individual';

export default function FirstStep() {
    const dispatch = useAppDispatch()
    const id = useTypedSelector((state) => state.RegisterUser.id)
    const [fullname, setFullname] = useState<string>('');
    const [fullAddress, setFullAddress] = useState<string>('');
    const [detailAddress, setDetailAddress] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [loadingMaps, setLoadingMaps] = useState<boolean>(false);
    const [lat, setLat] = useState<number>(0);
    const [lng, setLng] = useState<number>(0);
    const [getIndonesianAddressModal, setGetIndonesianAddressModal] = useState<boolean>(false);
    const [getCoordinatesModals, setGetCoordinatesModals] = useState<boolean>(false);
    const alamat = fullAddress.split(', ');
    const showCoordinates: boolean = lat !== 0 && lng !== 0
    const isUnactiveEnabled: boolean =
    fullname.length > 0 &&
    fullAddress.length > 0 &&
    postalCode.length > 0

    const handleSubmit = () => {
        if (id && fullname && fullAddress && postalCode) {     
            const dataForAdmin1 = {
                nama: fullname,
                fullAddress,
                code_pos: postalCode,
                address_description: detailAddress,
                lat_pin_alamat: lat,
                long_pin_alamat: lng
            };
            dispatch(setStep(2));
            dispatch(setId(id));
            
            dispatch(setDataAdmin1(dataForAdmin1));
        }
    }

  return (
    <section className="text-start w-full">
        <GetIndonesianAddress
                showModal={getIndonesianAddressModal}
                CloseModals={setGetIndonesianAddressModal}
                setAddress={setFullAddress}
                setKodePos={setPostalCode}
            />
            <GetCoordinate
                showModal={getCoordinatesModals}
                handleCloseMap={setGetCoordinatesModals}
                setLat={setLat}
                setLong={setLng}
                alamat={`${alamat[2]},${alamat[1]}, Indonesia`}
            />

                {/* Nama Lengkap */}
                <div className="mb-6">
                    <label className="flex items-center">
                        <p className="font-medium text-sm font-inter text-custom-third">Nama Lengkap</p>
                        <span className="text-[#E52427] ml-1">*</span>
                    </label>
                    <input
                        className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
                        type="text"
                        name="fullname"
                        value={fullname}
                        onChange={(e) => setFullname(e.target.value)}
                        placeholder="Masukan Nama Anda"
                    />
                </div>

                {/* Alamat Pemilik Toko */}
                <div className="mb-6 relative">
                    <label className="flex items-center">
                        <p className="font-medium text-sm font-inter text-custom-third">Alamat Pemilik Toko</p>
                        <span className="text-[#E52427] ml-1">*</span>
                    </label>
                    <input
                        className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3 cursor-pointer"
                        type="text"
                        value={fullAddress}
                        placeholder="Provinsi, Kota, Kecamatan, Kode Pos"
                        onFocus={() => setGetIndonesianAddressModal(true)}
                        readOnly
                    />
                    <div className="absolute bottom-4 right-4">
                        <ArrowRightInputVendor />
                    </div>
                </div>

                {/* Pin Alamat */}
                <div className="mb-6">
                    <label className="flex items-center">
                        <p className="font-medium text-sm font-inter text-custom-third">Pin Alamat</p>
                        <span className="text-[#E52427] ml-1">*</span>
                    </label>
                    <div
                        onClick={() => setGetCoordinatesModals(true)}
                        className="w-full max-w-[413px] h-[50px] bg-white rounded-lg border border-[#f46001] flex justify-center items-center cursor-pointer mt-2"
                    >
                        <img src={googleMapsIcon} alt="icon-gmaps" />
                        <p className="font-inter font-normal text-sm text-[#f46001] text-center ml-2">
                            {loadingMaps ? (
                                <div className="flex items-center justify-center">
                                    <LoadingSpinner width="18px" height="18px" />
                                    <span className="ml-2">Sedang Membuka Peta</span>
                                </div>
                            ) : showCoordinates ? (
                                `${lat}, ${lng}`
                            ) : (
                                'Pilih Lewat Peta'
                            )}
                        </p>
                    </div>
                </div>

                {/* Detail Alamat */}
                <div className="mb-6">
                    <label className="flex items-center">
                        <p className="font-medium text-sm font-inter text-custom-third">Detail Alamat</p>
                        <span className="text-[#E52427] ml-1">*</span>
                    </label>
                    <textarea
                        className="input-detail-alamat w-full max-w-[413px] font-poppins text-sm text-[#404040] resize-none border rounded-lg p-3"
                        name="detailAddress"
                        onChange={(e) => setDetailAddress(e.target.value)}
                        value={detailAddress}
                        placeholder="Contoh: Jl. Pahlawan No.12, Kel. Sukajadi"
                    ></textarea>
                </div>

                {/* Kode Pos */}
                <div className="mb-6">
                    <label className="flex items-center">
                        <p className="font-medium text-sm font-inter text-custom-third">Kode Pos</p>
                        <span className="text-[#E52427] ml-1">*</span>
                    </label>
                    <input
                        className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
                        type="number"
                        name="postalCode"
                        readOnly
                        value={postalCode}
                        placeholder="Masukan Kode Pos Anda"
                    />
                </div>

                {/* Tombol Submit */}
                <button
                    onClick={handleSubmit}
                    disabled={!isUnactiveEnabled}
                    className={`w-full max-w-[413px] h-[50px] rounded-[100px] py-4 mb-2 ${isUnactiveEnabled ? 'bg-custom-gradient cursor-pointer' : 'bg-[#F3F4F8] cursor-not-allowed'}`}
                >
                    <p className={`${isUnactiveEnabled ? 'text-white' : 'text-[#BFBFBF]'} font-medium text-sm font-roboto capitalize`}>
                        lanjut
                    </p>
                </button>
            </section>
  )
}
