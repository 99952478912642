import React from 'react'

export default function slider1() {
    return (
        <div className="flex flex-col items-center text-center mt-[20px]">
            <div className='flex gap-[0px] items-center justify-center'>
                <h1 className="text-[32px] font-inter font-semibold">
                    Peningkatan Status
                    {" "}
                    <span className='font-bold text-[32px] bg-custom-gradient bg-clip-text text-transparent'>Gizi</span>
                    {" "}
                    Anak
                </h1>
            </div>
            <p className="text-[#6D7588] text-[18px] font-inter">
                Makan Bergizi Gratis untuk mencukupi asupan gizi, mencegah malnutrisi serta memberikan edukasi tentang gizi
            </p>
        </div>
    )
}
