import { useTypedSelector } from '../../../../../../../redux/store';
import RecurringCard from './card';
import { RecurringIcon } from './icon';

export default function Recurring() {
    const schoolList = useTypedSelector((state)=>state.SchoolList.data)
    console.log(schoolList);
    
  return (
    <div className="w-full mx-auto bg-white rounded-lg px-6">
    {/* Header */}
    <h1 className="text-2xl font-bold text-center mb-4">Pesanan Katering<br/>Otomatis</h1>

    {/* Description with Image */}
    <div className="flex items-center space-x-4 bg-blue-50 p-2 rounded-xl">
      <RecurringIcon/>
      <p className="text-sm text-gray-600">
        Pesan katering makanan otomatis untuk memenuhi kebutuhan gizi yang sesuai dengan usia
      </p>
    </div>

    {/* Toggle Section */}
   {schoolList?.data.map((item, key)=>(
    <RecurringCard key={key} schoolName={item.namaSekolah} schoolId={item._id} recurring={item.isRecurring}/>
   ))}
  </div>
  )
}
