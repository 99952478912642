export {}; // Add this empty export statement to mark the file as a module

export const ArrowRightInput = () => (
    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L7 7L1 13" stroke="#222831" strokeWidth="2" strokeLinecap="round" />
    </svg>
);

export const LogoUploadDocument = () => (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.5 28C16.5 28.7373 15.9027 29.3333 15.1667 29.3333H7.16667C3.49067 29.3333 0.5 26.3427 0.5 22.6667V6.66667C0.5 2.99067 3.49067 0 7.16667 0H23.1667C26.8427 0 29.8333 2.99067 29.8333 6.66667V14.6667C29.8333 15.404 29.236 16 28.5 16C27.764 16 27.1667 15.404 27.1667 14.6667V6.66667C27.1667 4.46133 25.372 2.66667 23.1667 2.66667H7.16667C4.96133 2.66667 3.16667 4.46133 3.16667 6.66667V15.9453L6.91067 12.2013C8.95333 10.1573 12.2773 10.1573 14.3213 12.2013L21.4427 19.3227C21.964 19.844 21.964 20.6867 21.4427 21.208C21.1827 21.468 20.8413 21.5987 20.5 21.5987C20.1587 21.5987 19.8173 21.468 19.5573 21.208L12.436 14.0867C11.4333 13.0853 9.80133 13.084 8.796 14.0867L3.16667 19.716V22.6667C3.16667 24.872 4.96133 26.6667 7.16667 26.6667H15.1667C15.9027 26.6667 16.5 27.2627 16.5 28ZM20.5 4.66667C22.7053 4.66667 24.5 6.46133 24.5 8.66667C24.5 10.872 22.7053 12.6667 20.5 12.6667C18.2947 12.6667 16.5 10.872 16.5 8.66667C16.5 6.46133 18.2947 4.66667 20.5 4.66667ZM20.5 7.33333C19.7653 7.33333 19.1667 7.93067 19.1667 8.66667C19.1667 9.40267 19.7653 10 20.5 10C21.2347 10 21.8333 9.40267 21.8333 8.66667C21.8333 7.93067 21.2347 7.33333 20.5 7.33333ZM31.1667 24H27.1667V20C27.1667 19.2627 26.5693 18.6667 25.8333 18.6667C25.0973 18.6667 24.5 19.2627 24.5 20V24H20.5C19.764 24 19.1667 24.596 19.1667 25.3333C19.1667 26.0707 19.764 26.6667 20.5 26.6667H24.5V30.6667C24.5 31.404 25.0973 32 25.8333 32C26.5693 32 27.1667 31.404 27.1667 30.6667V26.6667H31.1667C31.9027 26.6667 32.5 26.0707 32.5 25.3333C32.5 24.596 31.9027 24 31.1667 24Z"
            fill="#1454B8"
        />
    </svg>
);
export const LogoUploadDocumentGradient = () => (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 28C16.5 28.7373 15.9027 29.3333 15.1667 29.3333H7.16667C3.49067 29.3333 0.5 26.3427 0.5 22.6667V6.66667C0.5 2.99067 3.49067 0 7.16667 0H23.1667C26.8427 0 29.8333 2.99067 29.8333 6.66667V14.6667C29.8333 15.404 29.236 16 28.5 16C27.764 16 27.1667 15.404 27.1667 14.6667V6.66667C27.1667 4.46133 25.372 2.66667 23.1667 2.66667H7.16667C4.96133 2.66667 3.16667 4.46133 3.16667 6.66667V15.9453L6.91067 12.2013C8.95333 10.1573 12.2773 10.1573 14.3213 12.2013L21.4427 19.3227C21.964 19.844 21.964 20.6867 21.4427 21.208C21.1827 21.468 20.8413 21.5987 20.5 21.5987C20.1587 21.5987 19.8173 21.468 19.5573 21.208L12.436 14.0867C11.4333 13.0853 9.80133 13.084 8.796 14.0867L3.16667 19.716V22.6667C3.16667 24.872 4.96133 26.6667 7.16667 26.6667H15.1667C15.9027 26.6667 16.5 27.2627 16.5 28ZM20.5 4.66667C22.7053 4.66667 24.5 6.46133 24.5 8.66667C24.5 10.872 22.7053 12.6667 20.5 12.6667C18.2947 12.6667 16.5 10.872 16.5 8.66667C16.5 6.46133 18.2947 4.66667 20.5 4.66667ZM20.5 7.33333C19.7653 7.33333 19.1667 7.93067 19.1667 8.66667C19.1667 9.40267 19.7653 10 20.5 10C21.2347 10 21.8333 9.40267 21.8333 8.66667C21.8333 7.93067 21.2347 7.33333 20.5 7.33333ZM31.1667 24H27.1667V20C27.1667 19.2627 26.5693 18.6667 25.8333 18.6667C25.0973 18.6667 24.5 19.2627 24.5 20V24H20.5C19.764 24 19.1667 24.596 19.1667 25.3333C19.1667 26.0707 19.764 26.6667 20.5 26.6667H24.5V30.6667C24.5 31.404 25.0973 32 25.8333 32C26.5693 32 27.1667 31.404 27.1667 30.6667V26.6667H31.1667C31.9027 26.6667 32.5 26.0707 32.5 25.3333C32.5 24.596 31.9027 24 31.1667 24Z" fill="url(#paint0_linear_1461_8279)"/>
    <defs>
    <linearGradient id="paint0_linear_1461_8279" x1="-6.80864" y1="-14.9333" x2="32.0259" y2="31.2494" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F9B607"/>
    <stop offset="1" stopColor="#E82C03"/>
    </linearGradient>
    </defs>
    </svg>
    
);
