import React, { useEffect, useState, useCallback } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import { IconClose } from '../../Vendor/assets/icons';

interface FormMapsProps {
    showModal: boolean;
    handleCloseMap: (value: boolean) => void;
    setLat: React.Dispatch<React.SetStateAction<number>>;
    setLong: React.Dispatch<React.SetStateAction<number>>;
    alamat: string;
}

type LatLngTuple = [number, number];

const customIcon = L.icon({
    iconUrl: 'https://www.svgrepo.com/show/313155/pin.svg',
    iconSize: [40, 40],
});

const GetCoordinate: React.FC<FormMapsProps> = ({ showModal, handleCloseMap, alamat, setLat, setLong }) => {
    const [markerPosition, setMarkerPosition] = useState<LatLngTuple>([0, 0]);
    const [isLoading, setIsLoading] = useState(true);

    const MapClickHandler: React.FC = () => {
        useMapEvents({
            click: (e) => {
                setMarkerPosition([e.latlng.lat, e.latlng.lng]);
            },
        });
        return null;
    };

    const findMyLocation = useCallback(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setMarkerPosition([position.coords.latitude, position.coords.longitude]);
                setIsLoading(false);
            },
            (error) => {
                console.error('Error obtaining location', error);
                setIsLoading(false);
            },
        );
    }, []);

    useEffect(() => {
        if (showModal) {
            setIsLoading(true);
            axios
                .get('https://api.opencagedata.com/geocode/v1/json', {
                    params: {
                        key: '64c302b0080b4cfeaf6fbbcdca4ab3f9',
                        q: alamat,
                        limit: 1,
                        pretty: 1,
                    },
                })
                .then((res) => {
                    const { lat, lng } = res.data.results[0].geometry;
                    setMarkerPosition([lat, lng]);
                })
                .catch((err) => {
                    console.error('Error fetching location:', err);
                    findMyLocation();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [showModal, alamat, findMyLocation]);

    const handleNext = () => {
        setLat(markerPosition[0]);
        setLong(markerPosition[1]);
        handleCloseMap(false);
    };

    if (!showModal) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white w-full max-w-[908px] max-h-[90vh] overflow-y-auto p-6 rounded-lg shadow-lg">
                <div className="flex justify-between mb-6">
                    <h2 className="text-2xl font-semibold font-inter">Tentukan titik pinpoint lokasi kamu</h2>
                    <button onClick={() => handleCloseMap(false)} className="text-gray-500 hover:text-gray-700">
                        {IconClose('24', '24')}
                    </button>
                </div>
                {!isLoading && (
                    <div className="h-[60vh] w-full mb-4">
                        <MapContainer
                            key={`${markerPosition[0]}-${markerPosition[1]}`}
                            center={markerPosition}
                            zoom={13}
                            scrollWheelZoom={false}
                            className="h-full w-full"
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={markerPosition} icon={customIcon}>
                                <Popup>Lokasi yang dipilih</Popup>
                            </Marker>
                            <MapClickHandler />
                        </MapContainer>
                    </div>
                )}
                <div className="flex justify-between gap-4">
                    <button
                        onClick={findMyLocation}
                        className="border border-blue-600 text-blue-600 rounded-full px-6 py-3 text-sm font-semibold hover:bg-blue-50 transition-colors"
                    >
                        Lokasi Saat ini
                    </button>
                    <button
                        onClick={handleNext}
                        className="bg-blue-600 text-white rounded-full px-8 py-3 text-lg font-semibold hover:bg-blue-700 transition-colors"
                    >
                        Lanjut
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GetCoordinate;
