import { RouterProvider } from 'react-router-dom';
import { routes } from './router/index';
import './index.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import { SearchProvider } from './konsumen/Components/searchConteks';

function App() {
    return (
        <div className="App">
            <SearchProvider>
                <RouterProvider router={routes} />
            </SearchProvider>
        </div>
    );
}

export default App;
