import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import { Link, useParams } from 'react-router-dom';
import { useTypedSelector } from '../../../../redux/store';

// Deklarasi tipe untuk Routing dari leaflet-routing-machine
declare module 'leaflet' {
    namespace Routing {
        interface ControlOptions {
            waypoints: L.LatLngExpression[];
            lineOptions?: {
                styles: { color: string; weight: number; opacity?: number }[];
                extendToWaypoints: boolean;
                missingRouteTolerance: number;
            };
        }
        function control(options: ControlOptions): Control;
    }
}

// Komponen RoutingControl untuk mengatur jalur dengan server OSRM
interface RoutingControlProps {
    waypoints: L.LatLngExpression[];
}

const RoutingControl: React.FC<RoutingControlProps> = ({ waypoints }) => {
    const map = useMap();
    const routingControlRef = useRef<L.Routing.Control | null>(null);

    useEffect(() => {
        if (routingControlRef.current === null) {
            routingControlRef.current = L.Routing.control({
                waypoints: waypoints.map((pos) => L.latLng(pos)),
                router: new L.Routing.OSRMv1({
                    serviceUrl: 'https://peta-backend.superdigitalapps.my.id/route/v1', // Pastikan tanpa trailing slash di sini
                }),
                lineOptions: {
                    styles: [
                        { color: '#6A1B9A', weight: 5 },
                        { color: '#AB47BC', weight: 4, opacity: 0.7 },
                    ],
                    extendToWaypoints: true,
                    missingRouteTolerance: 1,
                },
                routeWhileDragging: false,
                addWaypoints: false,
            }).addTo(map);
        }

        // Cleanup saat komponen di-unmount
        return () => {
            if (routingControlRef.current) {
                map.removeControl(routingControlRef.current);
                routingControlRef.current = null;
            }
        };
    }, [waypoints, map]);

    return null;
};

// Komponen untuk memastikan ukuran peta diperbarui ketika pertama kali dimuat
const MapInvalidateSize: React.FC = () => {
    const map = useMap();

    useEffect(() => {
        setTimeout(() => {
            map.invalidateSize();
        }, 500); // Tambahkan sedikit jeda untuk memastikan peta ter-render
    }, [map]);

    return null;
};

const DetailRiwayat: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { riwayatPengiriman } = useTypedSelector((state) => state.riwayatPengiriman);
    const selectedRiwayat = riwayatPengiriman.find((item) => item._id === id);

    if (!selectedRiwayat) {
        return <p>Data not found.</p>;
    }

    // Ambil koordinat toko dan pengiriman dari data yang diambil
    const storeLat = parseFloat(selectedRiwayat.id_toko.address.pinAlamat.lat.$numberDecimal);
    const storeLng = parseFloat(selectedRiwayat.id_toko.address.pinAlamat.long.$numberDecimal);
    const destLat = parseFloat(selectedRiwayat.orderId.addressId.pinAlamat.lat.$numberDecimal);
    const destLng = parseFloat(selectedRiwayat.orderId.addressId.pinAlamat.long.$numberDecimal);

    const waypoints: L.LatLngExpression[] = [
        [storeLat, storeLng],
        [destLat, destLng],
    ];

    return (
        <>
            <Link to="/distributor/profile/riwayat-pengiriman">Kembali</Link>
            <h4 className="font-semibold mt-4">Detail Riwayat Pengiriman</h4>
            <div className="w-full bg-white mt-2 shadow p-4 rounded-xl" style={{ height: '800px' }}>
                <MapContainer
                    center={[storeLat, storeLng]}
                    zoom={12}
                    scrollWheelZoom={false}
                    style={{ height: '100%', width: '100%' }}
                >
                    <MapInvalidateSize /> {/* Untuk memastikan ukuran peta diperbarui */}
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <RoutingControl waypoints={waypoints} />
                    <Marker position={[storeLat, storeLng]}>
                        <Popup>Pickup Location</Popup>
                    </Marker>
                    <Marker position={[destLat, destLng]}>
                        <Popup>Delivery Destination</Popup>
                    </Marker>
                </MapContainer>
            </div>
        </>
    );
};

export default DetailRiwayat;
