/* eslint-disable */
import axios from 'axios';
import './style.css';
import React, { useEffect, useMemo, useState } from 'react';
import { ArrowLeftIcon, CloseIcon, SearchIcon } from '../../konsumen/assets/icons';
interface getIndonesianAddress {
    showModal: boolean;
    CloseModals: (value: boolean) => void;
    setAddress: (value: string) => void;
    setKodePos: (value: string) => void;
}
interface Province {
    id: string;
    name: string;
}
interface Village {
    id: string;
    name: string;
    kode_pos: string;
}
const GetIndonesianAddress: React.FC<getIndonesianAddress> = ({ showModal, CloseModals, setAddress, setKodePos }) => {
    const [listProvince, setListProvince] = useState<Province[]>([]);
    const [listKabupaten, setListKabupaten] = useState<Province[]>([]);
    const [listKecamatan, setListKecamatan] = useState<Province[]>([]);
    const [listKelurahan, setListKelurahan] = useState<Village[]>([]);
    const [result, setResult] = useState<string[]>([]);
    if (result.length === 4) {
        console.log(result)
    }
    const [search, setSearch] = useState<string>('');
    const [step, setStep] = useState(1);
    const handleClose = () => {
        CloseModals(false);
        setStep(1);
    };
    const handleSubmitProvince = async (id: string, name: string) => {
        await setSearch('');
        const newResult = [...result];
        newResult[0] = name;
        setResult(newResult);
        axios
            .get(`${process.env.REACT_APP_API_URL}/alamat/provinsi/${id}`)
            .then((res) => {
                setListKabupaten(res.data.data[0].regencies);
                setStep(2);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleSubmitKabupaten = (id: string, name: string) => {
        setSearch('');
        const newResult = [...result];
        newResult[1] = name;
        setResult(newResult);
        axios
            .get(`${process.env.REACT_APP_API_URL}/alamat/regency/${id}`)
            .then((res) => {
                setListKecamatan(res.data.data[0].districts);
                setStep(3);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleSubmitKecamatan = (id: string, name: string) => {
        setSearch('');
        const newResult = [...result];
        newResult[2] = name;
        setResult(newResult);
        axios
            .get(`${process.env.REACT_APP_API_URL}/alamat/district/${id}`)
            .then((res) => {
                console.log(res);
                setListKelurahan(res.data.data[0].villages);
                setStep(4);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleSubmitDesa = (id: string, name: string, kode_pos: string) => {
        setSearch('');
        const newResult = [...result];
        newResult[3] = name;
        setResult(newResult);
        setKodePos(kode_pos);
        CloseModals(false);
        setStep(1);
        setAddress(result.join(', '));
    };
    const filteredProvinces = useMemo(() => {
        if (search === '') {
            return listProvince;
        }
        return listProvince.filter((province) => province.name.toLowerCase().includes(search.toLowerCase()));
    }, [listProvince, search]);

    const filteredKabupaten = useMemo(() => {
        if (search === '') {
            return listKabupaten;
        }
        return listKabupaten.filter((city) => city.name.toLowerCase().includes(search.toLowerCase()));
    }, [listKabupaten, search]);
    console.log(filteredKabupaten);

    const filteredKecamatan = useMemo(() => {
        if (search === '') {
            return listKecamatan;
        }
        return listKecamatan.filter((Kecamatan) => Kecamatan.name.toLowerCase().includes(search.toLowerCase()));
    }, [listKecamatan, search]);
    const filteredDesa = useMemo(() => {
        if (search === '') {
            return listKelurahan;
        }
        return listKelurahan.filter((Desa) => Desa.name.toLowerCase().includes(search.toLowerCase()));
    }, [listKelurahan, search]);
    useEffect(() => {
        setAddress(result.join(', '));
    }, [result[3]]);
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/alamat/provinsi`)
            .then((res) => {
                console.log(res);

                setListProvince(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    return (
        <div
            className={
                showModal ? 'fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50' : 'hidden'
            }
        >
            <div className="flex text-center flex-col justify-center bg-white  w-[413px] max-h-[720px] p-[24px] rounded-[8px] shadow-lg">
                <div className="flex justify-between items-center">
                    <p className="text-[#fff] cursor-default">
                        {step === 1 ? (
                            <span></span>
                        ) : (
                            <span onClick={() => setStep((value) => value - 1)}>
                                <ArrowLeftIcon />
                            </span>
                        )}
                    </p>
                    <h6 className="font-bold text-[18px] text-[#25282B] text-center">
                        {step === 1
                            ? 'Pilih Provinsi'
                            : step === 2
                              ? 'Pilih Kota / Kabupaten'
                              : step === 3
                                ? 'Pilih Kecamatan'
                                : step === 4
                                  ? 'Pilih Kelurahan'
                                  : null}
                    </h6>
                    <p onClick={handleClose} className="cursor-pointer">
                        <CloseIcon />
                    </p>
                </div>
                {step === 1 ? (
                    <div className="h-[589px]">
                        <div className="relative w-full max-w-md mt-[32px] mb-[16px]">
                            <input
                                value={search}
                                type="text"
                                placeholder="Cari Alamat"
                                className="pl-8 w-full py-2 px-4 pr-10 rounded-[8px] border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className="absolute inset-y-0 left-2 flex items-center pr-3 pointer-events-none">
                                <SearchIcon />
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-start">Provinsi</p>
                            <p className="text-end cursor-pointer"></p>
                        </div>
                        <div className="max-h-[450px] mt-[16px] overflow-y-auto custom-scrollbar">
                            {filteredProvinces.length > 0 ? (
                                filteredProvinces.map((item, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleSubmitProvince(item.id, item.name)}
                                        className="text-start cursor-pointer p-2 hover:bg-gray-100"
                                    >
                                        {item.name}
                                    </div>
                                ))
                            ) : (
                                <div className="text-start p-2 text-gray-500">Nama provinsi tidak ditemukan</div>
                            )}
                        </div>
                    </div>
                ) : step === 2 ? (
                    <div className="h-[589px]">
                        <div className="relative w-full max-w-md mt-[32px] mb-[16px]">
                            <input
                                value={search}
                                type="text"
                                placeholder="Cari Alamat"
                                className="pl-8 w-full py-2 px-4 pr-10 rounded-[8px] border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className="absolute inset-y-0 left-2 flex items-center pr-3 pointer-events-none">
                                <SearchIcon />
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-start">{result[0]}</p>
                            <p onClick={() => setStep(1)} className="text-end cursor-pointer">
                                Reset
                            </p>
                        </div>

                        <div className="h-[450px] mt-[16px] overflow-y-auto custom-scrollbar">
                            {filteredKabupaten.length > 0 ? (
                                filteredKabupaten.map((item, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleSubmitKabupaten(item.id, item.name)}
                                        className="text-start cursor-pointer p-2 hover:bg-gray-100"
                                    >
                                        {item.name}
                                    </div>
                                ))
                            ) : (
                                <div className="text-start p-2 text-gray-500">Nama Kabupaten/Kota tidak ditemukan</div>
                            )}
                        </div>
                    </div>
                ) : step === 3 ? (
                    <div className="h-[589px]">
                        <div className="relative w-full max-w-md mt-[32px] mb-[16px]">
                            <input
                                value={search}
                                type="text"
                                placeholder="Cari Alamat"
                                className="pl-8 w-full py-2 px-4 pr-10 rounded-[8px] border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className="absolute inset-y-0 left-2 flex items-center pr-3 pointer-events-none">
                                <SearchIcon />
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-start">{result[1]}</p>
                            <p onClick={() => setStep(1)} className="text-end cursor-pointer">
                                Reset
                            </p>
                        </div>

                        <div className="h-[450px] mt-[16px] overflow-y-auto custom-scrollbar">
                            {filteredKecamatan.length > 0 ? (
                                filteredKecamatan.map((item, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleSubmitKecamatan(item.id, item.name)}
                                        className="text-start cursor-pointer p-2 hover:bg-gray-100"
                                    >
                                        {item.name}
                                    </div>
                                ))
                            ) : (
                                <div className="text-start p-2 text-gray-500">Nama Kecamatan tidak ditemukan</div>
                            )}
                        </div>
                    </div>
                ) : step === 4 ? (
                    <div className="h-[589px]">
                        <div className="relative w-full max-w-md mt-[32px] mb-[16px]">
                            <input
                                value={search}
                                type="text"
                                placeholder="Cari Alamat"
                                className="pl-8 w-full py-2 px-4 pr-10 rounded-[8px] border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className="absolute inset-y-0 left-2 flex items-center pr-3 pointer-events-none">
                                <SearchIcon />
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-start">{result[2]}</p>
                            <p onClick={() => setStep(1)} className="text-end cursor-pointer">
                                Reset
                            </p>
                        </div>

                        <div className="h-[450px] mt-[16px] overflow-y-auto custom-scrollbar">
                            {filteredDesa.length > 0 ? (
                                filteredDesa.map((item, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleSubmitDesa(item.id, item.name, item.kode_pos)}
                                        className="text-start cursor-pointer p-2 hover:bg-gray-100"
                                    >
                                        {item.name}
                                    </div>
                                ))
                            ) : (
                                <div className="text-start p-2 text-gray-500">Nama Desa tidak ditemukan</div>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default GetIndonesianAddress;
