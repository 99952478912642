import React, { createContext, useState, ReactNode } from 'react';

// Definisikan tipe untuk context
interface SearchContextType {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  suggestions: string[];
  setSuggestions: (suggestions: string[]) => void;
  handleSearchChange: (term: string) => void;
}

// Buat context dengan default value
export const SearchContext = createContext<SearchContextType | undefined>(undefined);

// Buat provider untuk search context
export const SearchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
    // Panggil API atau logika untuk mendapatkan suggestions berdasarkan term
    // Di sini contoh hardcoded suggestions
    if (term) {
      setSuggestions(['Suggestion 1', 'Suggestion 2', 'Suggestion 3']);
    } else {
      setSuggestions([]);
    }
  };

  return (
    <SearchContext.Provider value={{ searchTerm, setSearchTerm, suggestions, setSuggestions, handleSearchChange }}>
      {children}
    </SearchContext.Provider>
  );
};
