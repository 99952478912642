/* eslint-disable */
import React, { useEffect, useState } from 'react';
import RecurringBar from './recurringBar';
import CardTransaksiRecurring from './card';
import Skeleton from '../AllTransaksiList/card/skeleton';
import OtomaticOrderNotFound from '../CateringOtomaticNotFound';
import { useAppDispatch, useTypedSelector } from '../../../../../../redux/store';
import { getRecurringData } from '../../../../../../redux/store/reducers/Konsumen/transaksi/recurring';

const CateringOtomatic: React.FC = () => {
    const dispatch = useAppDispatch()
    const dataRecurring = useTypedSelector((state) => state.Recurring)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (dataRecurring.loading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [dataRecurring])
    useEffect(() => {
        dispatch(getRecurringData())
    }, [dispatch])
    return (
        <div>
            {dataRecurring.data?.recurring.length === 0 ? (
                <OtomaticOrderNotFound />
            ) : null}
            {loading ? (
                <Skeleton />
            ) : (null)}
            {dataRecurring?.data?.isRecurring ? (
                <RecurringBar />
            ) : null}
            {dataRecurring?.data?.recurring
                ?.filter((item) => item !== null)
                .map((item, index) => {
                    const arrayProduct = item.arrayProduct?.[0];
                    const product = arrayProduct?.productId || item.productId;
                    const status_pengiriman = item.status_pengiriman?.[0];
                    return (
                        <div key={index}>
                            <CardTransaksiRecurring
                                date={item.createdAt || item.date || ''}
                                status={status_pengiriman?.status_pengiriman || ''}
                                namaToko={item.seller?.namaToko || item.dataToko?.namaToko || ''}
                                image={product?.image_product?.[0] || ''}
                                namaProduct={item.productId?.name_product || ''}
                                qty={arrayProduct?.quantity || item.quantity || 0}
                                harga={arrayProduct?.productId.price || item.productId?.total_price || 0}
                                totalPesanan={item.total_pesanan} />
                        </div>
                    )
                }
                )}
        </div>
    );
};

export default CateringOtomatic;
