import React, { useEffect, useState } from "react";
import { setDataAdmin2, setStep } from "../../../../../../../../../redux/store/reducers/Produsen/Auth/Individual";
import { useAppDispatch, useTypedSelector } from "../../../../../../../../../redux/store";
import { LogoUploadDocumentGradient } from "../../../../../../../../../konsumen/pages/register/sections/formAfterSelectRole/icons";

export default function SecondStep() {
  const dispatch = useAppDispatch();
  const [nik, setNik] = useState<number | null>();
  const [npwp, setNpwp] = useState<number | null>();
  const [ktp, setKtp] = useState<File | null>(null);
  const [npwpFile, setNpwpFile] = useState<File | null>(null);
  const [previewKTPUrl, setPreviewKTPUrl] = useState<string | null>(null);
  const [previewNpwpUrl, setPreviewNpwpUrl] = useState<string | null>(null);
  const isDisabledBtn = nik !== null && npwp !== null && ktp !== null && npwpFile !== null;

  const handleNpwpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNpwp(value ? parseInt(value, 10) : null);
};
  const handleKtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNik(value ? parseInt(value, 10) : null);
};
  const handleRemoveKtp = () => {
    setKtp(null);
    setPreviewKTPUrl(null);
};
  const handleRemoveNpwp = () => {
    setNpwpFile(null);
    setPreviewNpwpUrl(null);
};
  // General handle change for inputs
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(`Input Changed: ${name}, Value: ${value}`);
  };

  const handleSubmit = () => {
    dispatch(setStep(3));
    if (ktp && npwpFile && nik && npwp) {
      const dataadmin = {
        nik, 
        nomor_npwp: npwp, 
        file_ktp: ktp, 
        npwpFile 
      }
      dispatch(setDataAdmin2(dataadmin));
    }
  };
  useEffect(() => {
    if (npwpFile) {
        const objectUrl = URL.createObjectURL(npwpFile);
        setPreviewNpwpUrl(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }
}, [npwpFile]);
  useEffect(() => {
    if (ktp) {
        const objectUrl = URL.createObjectURL(ktp);
        setPreviewKTPUrl(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }
}, [ktp]);
  return (
    <form>
      {/* Input NIK */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 flex items-center">
          NIK <span className="text-red-500 ml-1">*</span>
        </label>
        <input
          type="tel"
          name="nik"
          placeholder="Masukkan Nomor NIK Anda"
          className="w-full h-[50px] rounded-lg border border-gray-300 p-4 mt-2 bg-white placeholder-gray-400 outline-none focus:border-blue-500"
          onChange={handleKtpChange}
        />
        <p className="mt-1 text-xs text-gray-500">
          Format NIK tidak valid.
        </p>
      </div>

      {/* Unggah KTP */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 flex items-center mb-2">
          Unggah KTP <span className="text-red-500 ml-1">*</span>
        </label>
        {previewKTPUrl === null ? (
          <div>
            <div className="flex items-center justify-center">
              <label className="flex flex-col items-center justify-center rounded-lg cursor-pointer border-2 border-dashed border-[#f46001] w-full h-[118px]">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <LogoUploadDocumentGradient />
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Unggah Media</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">Unggah Foto (PNG,JPG)</p>
                </div>
                <input
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  name="imageKTP"
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files && files.length > 0) {
                      const file = files[0];
                      setKtp(file);
                    }
                  }}
                  className="dashed-border hidden"
                />
              </label>

            </div>
            <div className="w-[326px] h-[31px] mt-[10px]">
              <p className="font-inter font-medium text-sm text-[#A6A7A8] text-left">
                *Catatan : silakan lakukan unggah Foto, size file (PNG/JPG) Max 1 MB
              </p>
            </div>
          </div>
        ) : (
          <div className="relative flex items-center justify-start">
            {previewKTPUrl && (
              <div className="relative">
                <img src={previewKTPUrl} alt="Preview NPWP" className="rounded-lg w-[200px] object-cover" />
                <button
                  onClick={handleRemoveKtp}
                  className="bg-[#D9D9D9] p-[3px] ml-[100px] mt-[-14px] text-[14px] rounded-full h-[25px] w-[25px] absolute top-2 -right-[10px] flex justify-center items-center"

                >
                  X
                </button>
              </div>
            )}
          </div>
        )}
        <p className="mt-1 text-xs text-gray-500">
          *Ukuran file maksimum 1 MB.
        </p>
      </div>

      {/* Input NPWP */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 flex items-center">
          NPWP <span className="text-red-500 ml-1">*</span>
        </label>
        <input
          type="tel"
          name="npwp"
          placeholder="Masukkan Nomor NPWP Anda"
          className="w-full h-[50px] rounded-lg border border-gray-300 p-4 mt-2 bg-white placeholder-gray-400 outline-none focus:border-blue-500"
          onChange={handleNpwpChange}
        />
        <p className="mt-1 text-xs text-gray-500">
          Format NPWP tidak valid.
        </p>
      </div>

      {/* Unggah NPWP */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 flex items-center mb-2">
          Unggah NPWP <span className="text-red-500 ml-1">*</span>
        </label>
        {previewNpwpUrl === null ? (
          <div>
            <div className="flex items-center justify-center mt-2">
              <label className="flex flex-col items-center justify-center rounded-lg cursor-pointer border-2 border-dashed border-[#f46001] w-full h-[118px]">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <LogoUploadDocumentGradient />
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Unggah Media</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">Unggah Foto (PNG,JPG)</p>
                </div>
                <input
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  name="imageKTP"
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files && files.length > 0) {
                      const file = files[0];
                      setNpwpFile(file);
                    }
                  }}
                  className="dashed-border hidden"
                />
              </label>

            </div>
            <div className="w-[326px] h-[31px] mt-[10px]">
              <p className="font-inter font-medium text-sm text-[#A6A7A8] text-left">
                *Catatan : silakan lakukan unggah Foto, size file (PNG/JPG) Max 1 MB
              </p>
            </div>
          </div>
        ) : (
          <div className="relative flex items-center justify-start">
            {previewNpwpUrl && (
              <div className="relative">
                <img src={previewNpwpUrl} alt="Preview NPWP" className="rounded-lg w-[200px] object-cover" />
                <button
                  onClick={handleRemoveNpwp}
                  className="bg-[#D9D9D9] p-[3px] ml-[100px] mt-[-14px] text-[14px] rounded-full h-[25px] w-[25px] absolute top-2 -right-[10px] flex justify-center items-center"

                >
                  X
                </button>
              </div>
            )}
          </div>
        )}
        <p className="mt-1 text-xs text-gray-500">
          *Ukuran file maksimum 1 MB.
        </p>
      </div>

      {/* Buttons */}
      <div className="flex justify-between mt-8 gap-3">
        <button
          type="button"
          onClick={() => dispatch(setStep(1))}
          className="w-1/3 h-[50px] border border-[#f46001] rounded-full 
                    bg-white py-3 px-3  font-roboto font-medium text-[14px] text-[#f46001] capitalize">
          Kembali
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          className={`w-2/3 ${isDisabledBtn ? 'bg-custom-gradient text-white' : 'bg-[#F3F4F8]'} 
            ${isDisabledBtn ? 'cursor-pointer' : 'cursor-not-allowed'} h-[50px] rounded-[100px] outline-none`}
        >
          Lanjut
        </button>
      </div>
    </form>
  );
}

