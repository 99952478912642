import HeroPage from '../../../../konsumen/Components/heroPage'
import FormSection from './formSection';

export default function RegisterProdusen() {
    return (
        <div className="flex flex-col md:flex-row justify-center items-start w-full h-auto md:h-screen p-4 md:p-0">
        {/* Section untuk HeroPage */}
        <section className="w-full md:w-1/2 text-center flex justify-center items-center md:fixed top-0 left-0 md:h-screen z-10 min-h-screen">
            <div className="h-full flex items-center justify-center">
                <HeroPage />
            </div>
        </section>
    
        {/* Section untuk Form Pendaftaran */}
        <section className="w-full md:w-1/2 text-center flex justify-center items-start min-h-screen py-24 md:ml-[50%]">
            <FormSection />
        </section>
    </div>
    

    )
}
