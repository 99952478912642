
export const profile = require('./profile.png');
export const IconGift = require('./gift.png');
export const Vespa = require('./vespa.png');
export const IconChecklis = require('./IconChecklist.png');
export const googleMapsIcon = require('./gmaps-icon.png');
export const BgOtp = require('./BgOtp.png');
export const AuthMbg1 = require('./AuthMbg.png');
export const AuthMbg2 = require('./AuthMbg2.png');
export const AuthMbg3 = require('./AuthMbg3.png');
