import { FC, ReactNode } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useTypedSelector } from '../../redux/store';

interface AuthGuardProps {
    children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
    const cookies = new Cookies();
    const token = cookies.get('token');
    const userroleinfo = useTypedSelector((state) => state.UserInfo);

    if (token) {
        if (userroleinfo.role === 'konsumen') {
            return <Navigate to="/" replace />;
        } else if (userroleinfo.role === 'distributor') {
            return <Navigate to="/distributor/dashboard" replace />;
        } else if (userroleinfo.role === 'vendor') {
            return <Navigate to="/vendor/pesanan" replace />;
        } else if (userroleinfo.role === 'supplier') {
            const supplierUrl = process.env.REACT_APP_SUPPLIER_URL;
            window.location.href = `${supplierUrl}`;
        }
    }
    return <>{children}</>;
};

export default AuthGuard;
