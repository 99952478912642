import React from 'react'

export default function Skeleton() {
    const data = [1, 2, 3]
    return (
        <div className='animate-pulse'>
            {data.map((data, key) => (
                <div key={key} className="mt-[16px] p-4">
                    <div className="text-[16px] font-inter flex flex-wrap gap-4">
                        <div className="bg-gray-200 w-[80px] h-[24px] rounded"></div>
                        <div className="bg-gray-200 w-[100px] h-[24px] rounded"></div>
                        <div className="bg-gray-200 w-[80px] h-[24px] rounded-full"></div>
                        <div className="bg-gray-200 w-[120px] h-[24px] rounded"></div>
                    </div>

                    <div className="bg-gray-200 w-[200px] h-[24px] rounded mt-4"></div>

                    <div className="flex gap-5 h-[131px] mt-4">
                        <div className="bg-gray-200 w-[138px] h-[131px] rounded-md"></div>
                        <div className="w-full">
                            <div className="bg-gray-200 w-[180px] h-[24px] rounded"></div>
                            <div className="text-[16px] font-inter flex gap-5 mt-2">
                                <div className="bg-gray-200 w-[140px] h-[24px] rounded"></div>
                                <div className="bg-gray-200 w-[140px] h-[24px] rounded"></div>
                            </div>
                            <div className="bg-gray-200 w-[120px] h-[24px] rounded mt-2"></div>
                            <div className="flex justify-between py-[4px] mt-2">
                                <div className="bg-gray-200 w-[100px] h-[24px] rounded"></div>
                                <div className="flex justify-between gap-[16px] cursor-pointer">
                                    <div className="bg-gray-200 w-[120px] h-[24px] rounded"></div>
                                    <div className="bg-gray-200 w-[80px] h-[32px] rounded-full"></div>
                                    <div className="bg-gray-200 w-[80px] h-[32px] rounded-full"></div>
                                    <div className="bg-gray-200 w-[120px] h-[32px] rounded-full"></div>
                                    <div className="bg-gray-200 w-[120px] h-[32px] rounded-full"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            ))}
        </div>
    )
}
