import { FC, ReactNode } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

interface AuthGuardProps {
    children: ReactNode;
}

const FormGuard: FC<AuthGuardProps> = ({ children }) => {
    const cookies = new Cookies();
    const token = cookies.get('token');

    if (!token) {
        return <Navigate to="/auth/login" replace />;
    }
    return <>{children}</>;
};

export default FormGuard;
