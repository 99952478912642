import React from 'react';
import { CardTransaksiProps } from './interface';
import { FormatDateIndonesia, formatToIDR } from '../../../../../../../GlobalComponent/function';

const CardTransaksiRecurring: React.FC<CardTransaksiProps> = ({
    date,
    status,
    namaToko,
    image,
    namaProduct,
    qty,
    harga,
    totalPesanan,
}) => {
    return (
        <div className="rounded-md p-4 shadow-sm w-full">
            {/* Header */}
            <div className="flex justify-start gap-2 items-center mb-2">
                <p className="text-sm text-gray-500">Produk {FormatDateIndonesia(date)}</p>
                {status !== '' ? (
                    <span className="bg-blue-100 text-blue-500 capitalize text-xs font-semibold px-2 py-1 rounded-full">
                        {status}
                    </span>
                ) : (
                    <p></p>
                )}
            </div>
            <p className="font-semibold text-lg mb-3">{namaToko}</p>
            {/* Content Produk */}
            <div className="flex items-center gap-4">
                <img
                    src={image}
                    alt={namaProduct}
                    className="w-24 h-24 object-cover rounded-md"
                />
                <div className="flex-1">
                    <p className="font-medium">{namaProduct}</p>
                    <p className="text-sm text-gray-500">
                        {qty} x Rp {formatToIDR(harga)}{' '}
                        <span>+ Produk lainnya</span>
                    </p>
                    <div className="mt-4">
                        <p className="text-gray-500 text-sm">Total Pesanan</p>
                        <p className="font-semibold">Rp {formatToIDR(totalPesanan)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardTransaksiRecurring;
