import { SekolahProps } from '../../redux/store/reducers/Konsumen/schoolList/interface';
import { DateTime } from 'luxon';

export const FormatDateIndonesia = (isoDate: string) => {
    const date = new Date(isoDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day} ${getMonthName(month)} ${year}, ${hours}:${minutes} WIB`;
};
export const getMonthName = (month: string) => {
    const monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
    ];
    return monthNames[parseInt(month) - 1];
};
export const getDayName = () => {
    const hariIndonesia = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

    const tanggalSekarang = new Date();
    const hariIni = hariIndonesia[tanggalSekarang.getDay()];

    return hariIni;
};
export function formatToOneDecimal(angka: number | string): number {
    let parsedNumber = typeof angka === 'string' ? parseFloat(angka) : angka;
    return parseFloat(parsedNumber.toFixed(1));
}
export function getMonthNameAndYear(dateString: string): string {
    const date = new Date(dateString);

    // Array nama bulan
    const monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
    ];

    // Mengambil bulan (bulan dimulai dari 0, jadi langsung ambil)
    const month = monthNames[date.getMonth()];

    // Mengambil tahun
    const year = date.getFullYear();

    return `${month} ${year}`;
}
export function findSchoolName(idSekolah: string, sekolahArray: SekolahProps[]) {
    const sekolahDitemukan = sekolahArray.find((s) => s._id === idSekolah);
    return sekolahDitemukan ? sekolahDitemukan.namaSekolah : 'Sekolah tidak ditemukan';
}
export const findTotalStudent = (sekolahId: string, listSekolah: SekolahProps[]) => {
    const sekolah = listSekolah.find((skl) => skl._id === sekolahId);

    if (sekolah) {
        const jumlahTotalSiswa = sekolah.kelas.reduce((total, kelas) => total + kelas.jumlahMuridKelas, 0);
        return jumlahTotalSiswa;
    } else {
        console.log('Sekolah tidak ditemukan.');
        return 0;
    }
};
export const findSchoolAddress = (sekolahId: string, listSekolah: SekolahProps[]) => {
    const sekolah = listSekolah.find((skl) => skl._id === sekolahId);

    if (sekolah) {
        const schoolAddress = sekolah.address.province;
        return schoolAddress;
    } else {
        console.log('Sekolah tidak ditemukan.');
        return 0;
    }
};
export function formatToIDR(number: number): string {
    return new Intl.NumberFormat('id-ID', { maximumFractionDigits: 0 }).format(number);
}
export const formatDateTimeToISO = (date: DateTime): string => {
    const isoString = date.toISO();
    return isoString !== null ? isoString : '';
};
export  const formatDateTime = (date: DateTime): string => {
    return date.toFormat('dd LLLL yyyy');
};

export const colorBorderByRoles = (roles: any) => {
    if (roles === 'konsumen') {
        return '#1454B8';
    } else if (roles === 'vendor') {
        return '#007F73';
    } else if (roles === 'distributor') {
        return '#5F5D9C';
    }
}

export const colorTextByRoles = (roles: any) => {
    if (roles === 'konsumen') {
        return 'text-[#1454B8]';
    } else if (roles === 'vendor') {
        return 'text-custom-primaryvendor';
    } else if (roles === 'distributor') {
        return 'text-custom-primarydistributor';
    }
}
export const backgroundByRoles = (roles: any) => {
    if (roles === 'konsumen') {
        return 'bg-[#1454B8]';
    } else if (roles === 'vendor') {
        return 'bg-custom-primaryvendor';
    } else if (roles === 'distributor') {
        return 'bg-custom-primarydistributor';
    }
}

export const getStyles = (isValid: boolean, roles: any) =>
    !isValid
        ? 'text-[#BCBCBC] bg-[#E1E1E1]'
        : roles === 'konsumen'
        ? 'text-[#fff] bg-[#1454B8]'
        : roles === 'vendor'
        ? 'text-[#fff] bg-custom-primaryvendor'
        : roles === 'distributor'
        ? 'text-[#fff] bg-custom-primarydistributor'
        : 'text-[#BCBCBC] bg-[#E1E1E1]';
