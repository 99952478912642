export const OtomaticOrderIcon = () => (
    <svg
    width={320}
    height={318}
    viewBox="0 0 320 318"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M315.294 135.826C306.621 79.0916 271.06 9.39263 200.804 2.09871C200.804 2.09871 147.463 -4.38516 94.8794 5.44031C63.6716 11.272 36.3035 28.6334 18.9914 53.5859C1.6792 78.5385 -4.10689 108.964 2.91299 137.973C7.47222 156.814 10.5532 176.313 9.70687 195.672C8.23555 229.366 9.08191 260.816 41.8037 280.91C71.0201 298.852 107.789 314.671 142.868 317.447C166.706 319.331 193.44 316.282 216.417 310.081C333.509 278.47 323.966 192.56 315.294 135.826Z"
      fill="#F4F7FA"
    />
    <path d="M199.827 32.3281H31.9229V256.177H199.827V32.3281Z" fill="white" />
    <path
      d="M193.157 247.163H38.1997V40.1719H193.157V247.163ZM39.3758 245.987H191.98V41.7401H39.3758V245.987Z"
      fill="#97A6B7"
    />
    <path
      d="M192.765 71.9219H38.9839V73.0992H192.765V71.9219Z"
      fill="#97A6B7"
    />
    <path d="M181.387 106.43H49.9668V107.998H181.387V106.43Z" fill="#97A6B7" />
    <path
      d="M181.387 200.508H49.9668V202.076H181.387V200.508Z"
      fill="#97A6B7"
    />
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.7}
    >
      <path
        d="M133.523 79.3672H51.1411V87.6002H133.523V79.3672Z"
        fill="#97A6B7"
      />
    </g>
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.7}
    >
      <path
        d="M133.523 92.7031H51.1411V100.543H133.523V92.7031Z"
        fill="#97A6B7"
      />
    </g>
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.7}
    >
      <path
        d="M182.185 51.3906H48.6597V59.2307H182.185V51.3906Z"
        fill="#97A6B7"
      />
    </g>
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.7}
    >
      <path
        d="M133.523 116.617H51.1411V124.85H133.523V116.617Z"
        fill="#97A6B7"
      />
      <path
        d="M133.525 131.102H80.957V138.942H133.525V131.102Z"
        fill="#97A6B7"
      />
      <path
        d="M172.362 116.617H160.593V124.85H172.362V116.617Z"
        fill="#97A6B7"
      />
      <path
        d="M172.362 92.5078H147.677V100.741H172.362V92.5078Z"
        fill="#97A6B7"
      />
      <path
        d="M172.362 79.3672H147.677V87.6002H172.362V79.3672Z"
        fill="#97A6B7"
      />
      <path
        d="M172.362 131.102H160.593V138.942H172.362V131.102Z"
        fill="#97A6B7"
      />
      <path
        d="M133.523 143.656H51.1411V151.889H133.523V143.656Z"
        fill="#97A6B7"
      />
      <path
        d="M133.525 158.164H80.957V166.004H133.525V158.164Z"
        fill="#97A6B7"
      />
      <path
        d="M172.362 143.656H160.593V151.889H172.362V143.656Z"
        fill="#97A6B7"
      />
      <path
        d="M172.362 158.164H160.593V166.004H172.362V158.164Z"
        fill="#97A6B7"
      />
      <path
        d="M133.523 170.719H51.1411V178.952H133.523V170.719Z"
        fill="#97A6B7"
      />
      <path
        d="M133.525 185.211H80.957V193.051H133.525V185.211Z"
        fill="#97A6B7"
      />
      <path
        d="M172.362 170.719H160.593V178.952H172.362V170.719Z"
        fill="#97A6B7"
      />
      <path
        d="M172.362 185.211H160.593V193.051H172.362V185.211Z"
        fill="#97A6B7"
      />
    </g>
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.7}
    >
      <path
        d="M133.523 209.523H51.1411V217.364H133.523V209.523Z"
        fill="#97A6B7"
      />
      <path
        d="M133.525 223.641H80.957V231.874H133.525V223.641Z"
        fill="#97A6B7"
      />
      <path
        d="M172.362 209.523H160.593V217.364H172.362V209.523Z"
        fill="#97A6B7"
      />
      <path
        d="M172.362 223.641H160.593V231.874H172.362V223.641Z"
        fill="#97A6B7"
      />
    </g>
    <path
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.5}
      d="M199.827 239.149V256.178H31.9229V32.3281H36.9087C59.3936 44.4812 56.3421 73.0321 58.0579 127.441C71.6064 179.064 176.288 200.923 199.827 239.149Z"
      fill="url(#paint0_linear_2851_159053)"
    />
    <path
      d="M28.4599 263.573C27.9652 266.223 28.242 268.981 28.6202 271.627C28.9926 274.231 29.666 276.746 31.9133 278.369C32.396 278.727 32.9193 279.026 33.4723 279.261C34.08 279.51 34.8306 279.595 35.3668 279.986C36.2512 280.63 36.6282 282.007 37.327 282.846C39.0151 284.874 41.3478 286.047 43.9606 286.309C44.5844 286.371 45.2509 286.395 45.8263 286.666C46.4766 286.974 46.9793 287.57 47.5351 288.009C48.5594 288.816 49.8285 289.249 51.1327 289.237C52.3861 289.248 53.6302 288.925 54.8225 288.573C56.2327 288.159 57.6383 287.73 59.0462 287.306C60.4541 286.882 61.862 286.492 63.2537 286.038C64.4068 285.663 65.5391 285.165 66.4305 284.327C67.3944 283.422 67.7911 282.216 68.2581 281.017C68.4795 280.448 68.7193 279.809 69.199 279.404C69.6786 278.998 70.3128 278.849 70.9009 278.702C73.305 278.099 75.5731 277.188 77.2877 275.322C78.7717 273.709 79.5524 271.433 79.2145 269.247C79.1845 269.054 79.143 268.863 79.0992 268.671C79.3421 268.544 79.5272 268.329 79.6169 268.07C81.0525 263.674 79.4786 258.514 75.6216 255.836C74.5446 255.089 73.4088 254.731 72.2246 254.21C71.0923 253.711 70.5446 252.584 69.7351 251.71C67.9836 249.817 65.7213 250.004 63.3852 250.349C62.3221 250.506 61.3028 250.615 60.2869 250.183C59.1188 249.685 58.0961 248.909 56.9338 248.399C55.7507 247.882 54.4916 247.677 53.2163 247.921C52.0817 248.139 50.9851 248.662 49.8263 248.728C48.5348 248.801 47.2722 248.163 46.0211 247.912C44.8696 247.686 43.6924 247.619 42.5227 247.714C40.1094 247.903 37.5934 248.78 35.7196 250.343C34.7061 251.191 33.8113 252.227 33.3512 253.483C33.1336 254.105 32.995 254.752 32.9384 255.409C32.8762 255.995 32.8439 256.561 32.4599 257.04C32.046 257.552 31.4809 257.938 31.014 258.399C30.5565 258.861 30.1552 259.375 29.8182 259.931C29.1426 261.05 28.682 262.285 28.4599 263.573Z"
      fill="url(#paint1_linear_2851_159053)"
    />
    <path
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.4}
      d="M31.2491 258.188C33.4699 262.355 36.145 266.336 38.3162 270.538C39.7541 272.972 42.1906 274.505 44.4805 275.916C45.7652 276.602 47.1892 276.987 48.6448 277.042C50.1003 277.097 51.5492 276.82 52.8818 276.232C58.2413 274.259 61.5782 269.366 67.595 268.682C71.5973 268.318 75.5039 268.366 79.2409 269.489C79.4865 271.599 78.7128 273.765 77.2807 275.318C75.5661 277.184 73.298 278.095 70.8939 278.698C70.3058 278.846 69.6636 278.996 69.192 279.4C68.7203 279.803 68.4724 280.446 68.2511 281.013C67.7898 282.212 67.3874 283.418 66.4234 284.323C65.5321 285.161 64.3987 285.66 63.2467 286.035C61.855 286.489 60.4413 286.882 59.0392 287.302C57.6371 287.723 56.2246 288.151 54.8155 288.57C53.6209 288.921 52.3768 289.244 51.1257 289.233C49.8219 289.245 48.5533 288.812 47.5293 288.005C46.9689 287.567 46.4707 286.968 45.8204 286.663C45.2439 286.391 44.5786 286.369 43.9547 286.305C41.3419 286.043 39.0092 284.87 37.3211 282.842C36.6235 282.003 36.2465 280.626 35.3609 279.982C34.8247 279.591 34.0741 279.505 33.4664 279.257C32.9135 279.022 32.3902 278.723 31.9075 278.365C29.6602 276.743 28.9856 274.227 28.6143 271.623C28.2361 268.973 27.9594 266.219 28.454 263.569C28.677 262.281 29.1384 261.047 29.8147 259.929C30.1516 259.373 30.553 258.858 31.0104 258.396C31.0888 258.329 31.1707 258.258 31.2491 258.188Z"
      fill="url(#paint2_linear_2851_159053)"
    />
    <path
      d="M63.2988 230.333C61.0446 227.734 57.8852 225.81 54.4963 225.269C55.4072 221.86 54.185 217.979 51.104 215.821C47.0855 212.999 42.3407 213.467 38.1031 215.284C34.554 214.203 30.5551 214.362 27.1697 215.847C19.6852 219.125 16.4762 227.634 18.7385 235.08C17.1036 235.557 15.6147 236.437 14.408 237.638C13.2014 238.839 12.3155 240.324 11.8316 241.956C10.5805 246.237 12.3413 250.552 15.7059 253.31C17.4136 254.71 19.3773 255.746 21.2164 256.957C23.2389 258.289 24.5096 259.94 26.042 261.77C29.04 265.354 33.5796 267.416 38.2749 267.162C42.441 266.931 46.2127 264.787 48.7863 261.612C51.3127 263.063 54.3845 263.361 57.274 262.72C61.3859 261.806 64.6871 259.077 66.5908 255.345C70.4456 247.799 68.814 236.69 63.2988 230.333Z"
      fill="url(#paint3_linear_2851_159053)"
    />
    <path
      d="M97.8233 268.823C100.034 268.512 106.219 266.703 109.469 263.469C112.893 260.059 112.315 253.168 113.705 251.423C115.094 249.677 119.191 248.445 121.866 245.152C125.738 240.385 127.009 233.824 124.211 229.798C123.006 228.07 119.491 225.289 119.968 222.748C120.51 221.498 124.488 219.176 123.938 211.433C123.499 205.256 119.938 195.877 107.702 193.374C105.308 191.981 105.605 186.613 99.3453 184.025C93.0853 181.437 89.143 185.426 86.8588 184.098C84.5745 182.769 79.0756 179.434 73.467 179.281C66.9337 179.102 60.6968 182.107 55.9404 187.331C54.7824 188.619 53.7622 190.025 52.8963 191.525C52.5342 192.143 52.4351 192.367 51.7236 192.4C50.8819 192.438 50.0678 192.425 49.2145 192.515C46.1912 192.835 42.6697 193.463 39.9335 195.165C34.4057 198.592 31.199 204.932 30.8312 210.643C30.4852 216.058 35.9127 225.76 35.3408 227.381C34.7689 229.001 29.1627 234.294 29.4187 240.782C29.7438 249.034 33.8338 255.842 41.3068 259.448C44.6011 261.04 53.6954 263.143 56.0984 264.749C58.5013 266.355 58.0274 274.06 61.7968 277.48C65.8902 281.194 70.3733 280.949 76.6564 279.162C80.0545 278.195 82.1035 278.644 84.9158 276.314C88.1571 277.31 92.4212 275.519 95.1309 272.87C96.3508 271.672 95.6129 269.134 97.8233 268.823Z"
      fill="url(#paint4_linear_2851_159053)"
    />
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.4}
    >
      <path
        d="M107.15 201.637C98.5254 200.397 92.2908 207.579 84.2758 208.579C76.2608 209.58 74.3594 199.086 69.9489 193.683C68.4142 191.663 66.3421 189.691 64.2723 189.204C61.0918 188.364 57.7551 188.306 54.5474 189.036C54.9809 188.46 55.441 187.889 55.931 187.346C60.6898 182.122 66.9267 179.118 73.4577 179.297C79.0662 179.45 84.5652 182.783 86.8494 184.113C89.1336 185.443 93.076 181.453 99.336 184.041C105.596 186.629 105.299 191.991 107.692 193.39C118.13 195.523 122.254 202.661 123.515 208.548L123.005 208.161C118.626 204.507 113.168 202.439 107.15 201.637Z"
        fill="url(#paint5_linear_2851_159053)"
      />
      <path
        d="M39.9268 195.183C41.8063 194.017 44.0571 193.356 46.2617 192.953C42.8798 195.817 38.0807 199.603 40.3246 203.676C44.0536 210.492 51.8737 214.776 54.0922 222.804C56.3591 231.374 47.9314 236.73 41.0625 239.921C38.6019 241.09 38.4105 243.403 39.2845 245.618C41.5999 251.753 46.912 256.669 53.2459 257.983C68.253 261.072 82.4554 256.81 97.639 255.833C101.659 255.468 105.167 258.388 108.213 260.673C109.642 259.403 111.029 258.089 112.372 256.731C111.911 259.125 111.208 261.75 109.472 263.479C106.224 266.714 100.039 268.523 97.8258 268.834C95.6131 269.145 96.3522 271.683 95.1276 272.881C92.4179 275.531 88.1539 277.321 84.9126 276.325C82.1014 278.655 80.0512 278.206 76.6531 279.172C70.3701 280.96 65.8869 281.204 61.7935 277.491C58.0242 274.071 58.4981 266.365 56.0951 264.76C53.6921 263.155 44.599 261.052 41.3035 259.459C33.8305 255.85 29.7406 249.043 29.4154 240.792C29.1571 234.316 34.7622 229.016 35.3341 227.403C35.906 225.79 30.4762 216.078 30.8244 210.665C31.1923 204.949 34.399 198.61 39.9268 195.183Z"
        fill="url(#paint6_linear_2851_159053)"
      />
    </g>
    <path
      d="M48.7604 236.525C70.8762 254.229 77.1674 286.073 77.1674 286.073L97.7496 273.205C69.5617 240.867 76.1354 227.511 75.8978 213.7C75.8978 213.7 74.1832 210.337 72.9944 214.195C72.366 216.234 71.5185 224.49 72.9102 233.669C66.2536 223.821 67.2983 215.217 64.9841 212.582C64.1977 211.687 62.0045 211.939 62.0045 211.939C62.0045 211.939 65.2147 235.168 77.8638 260.308C77.8638 260.308 63.7387 235.825 55.8056 230.282C54.3205 229.274 53.2989 230.541 54.4865 232.886C54.4865 232.886 58.1187 237.105 62.1383 244.303C55.0446 238.172 53.8581 236.698 51.3582 234.616C48.8584 232.534 47.6165 234.191 48.7604 236.525Z"
      fill="url(#paint7_linear_2851_159053)"
    />
    <path
      d="M129.202 233.812C132.001 233.186 134.005 230.609 134.517 227.665C135.144 224.062 134.094 220.212 132.159 217.162C128.585 211.531 121.733 208.971 115.379 210.378C114.397 208.447 112.444 206.998 110.246 206.824C108.221 206.664 106.42 207.506 105.173 208.906C104.049 208.845 102.923 208.948 101.829 209.213C99.1411 209.888 96.8591 212.314 96.8338 215.029C93.1244 216.37 91.1468 221.009 90.8828 224.747C90.6937 227.415 91.0765 230.393 93.0921 232.352C93.9292 233.158 95.0523 233.331 95.1503 234.588C95.2495 235.784 95.3705 236.947 95.8421 238.07C96.7843 240.309 98.471 242.153 100.617 243.293C101.547 243.788 102.553 244.125 103.593 244.289C104.772 244.472 106.197 244.013 107.306 244.334C107.946 244.52 108.547 245.202 109.087 245.576C109.592 245.941 110.138 246.247 110.713 246.487C111.802 246.903 112.968 247.084 114.132 247.016C116.371 246.91 118.523 245.935 120.412 244.794C124.351 242.416 128.533 238.549 129.202 233.812Z"
      fill="url(#paint8_linear_2851_159053)"
    />
    <path
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.4}
      d="M97.4838 231.95C98.5319 233.07 100.067 235.09 100.822 234.485C107.715 228.592 116.609 232.778 124.306 234.237C128.838 235.044 131.225 230.097 132.539 226.737C133.249 225.09 133.786 223.374 134.142 221.617C134.693 223.592 134.865 225.671 134.516 227.674C134.004 230.618 131.999 233.194 129.2 233.82C128.531 238.557 124.35 242.424 120.415 244.802C118.526 245.943 116.379 246.918 114.135 247.024C112.971 247.092 111.806 246.911 110.716 246.495C110.141 246.255 109.596 245.95 109.091 245.585C108.551 245.21 107.949 244.528 107.309 244.342C106.2 244.02 104.772 244.48 103.596 244.297C102.554 244.132 101.547 243.795 100.617 243.298C98.4646 242.162 96.7719 240.317 95.8257 238.076C95.3644 236.953 95.2376 235.79 95.1338 234.594C95.03 233.337 93.9116 233.169 93.0756 232.358C91.06 230.399 90.6784 227.421 90.8663 224.753C90.8802 224.555 90.8998 224.355 90.924 224.153C93.0883 226.724 95.1154 229.377 97.4838 231.95Z"
      fill="url(#paint9_linear_2851_159053)"
    />
    <path
      d="M56.4559 225.347C54.0495 223.4 51.1126 222.172 48.2334 221.109C45.4003 220.061 42.4911 219.367 39.6257 220.748C39.0008 221.04 38.4125 221.404 37.8731 221.834C37.2908 222.311 36.7938 223.02 36.1066 223.348C34.9731 223.888 33.3761 223.515 32.1481 223.761C29.1778 224.357 26.7194 226.066 25.0267 228.561C24.6232 229.155 24.2357 229.815 23.643 230.247C22.9777 230.736 22.1025 230.912 21.3542 231.237C19.9371 231.854 18.8924 232.85 18.1487 234.194C17.4568 235.452 17.0994 236.883 16.8019 238.281C16.456 239.93 16.11 241.582 15.7722 243.236C15.4344 244.889 15.0561 246.52 14.754 248.172C14.5038 249.539 14.3885 250.954 14.7471 252.311C15.1357 253.778 16.1308 254.837 17.0925 255.963C17.5468 256.496 18.0587 257.09 18.2109 257.794C18.3631 258.498 18.1625 259.22 17.9907 259.893C17.2862 262.647 16.9679 265.432 17.9158 268.18C18.7356 270.558 20.607 272.589 22.9985 273.442C23.2106 273.518 23.4263 273.579 23.643 273.634C23.6393 273.949 23.7555 274.253 23.9682 274.485C27.6246 278.334 33.6955 279.565 38.5061 277.135C39.8483 276.457 40.8307 275.507 42.0045 274.6C43.1276 273.73 44.5643 273.793 45.8892 273.454C48.758 272.722 49.8027 270.343 50.7321 267.793C51.1553 266.641 51.6015 265.548 52.592 264.759C53.7324 263.853 55.0757 263.246 56.2242 262.352C57.3934 261.442 58.2881 260.278 58.7378 258.864C59.138 257.597 59.2083 256.214 59.7756 255.005C60.4063 253.663 61.7358 252.738 62.6756 251.618C63.5328 250.58 64.2428 249.429 64.7857 248.198C65.9134 245.663 66.4 242.644 65.8442 239.901C65.5421 238.416 64.984 236.947 63.9659 235.797C63.4568 235.238 62.8796 234.745 62.2478 234.329C61.6897 233.945 61.1305 233.601 60.8641 232.955C60.5735 232.264 60.4928 231.477 60.2876 230.754C60.0707 230.041 59.7703 229.355 59.3928 228.712C58.6467 227.406 57.609 226.288 56.4559 225.347Z"
      fill="url(#paint10_linear_2851_159053)"
    />
    <path
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.4}
      d="M21.3511 231.238C22.0994 230.913 22.9734 230.736 23.6399 230.249C24.2291 229.816 24.6154 229.156 25.0236 228.562C26.375 226.572 28.2153 225.082 30.4142 224.258L29.8584 225.139C29.6151 225.432 29.298 226.407 29.2012 226.819C26.7659 231.227 22.3566 236.217 24.7941 240.722C25.9634 243.181 29.3015 244.227 30.5929 246.539C32.7838 249.85 31.422 254.159 32.1784 258.006C32.5681 260.806 32.0631 264.092 35.2732 265.285C40.582 267.204 46.5376 267.252 51.8417 265.531C51.3389 266.197 51.0288 266.977 50.7301 267.793C49.8007 270.339 48.7561 272.723 45.8872 273.454C44.5612 273.793 43.1199 273.73 42.0025 274.6C40.8287 275.509 39.8475 276.459 38.5041 277.135C33.6935 279.563 27.6226 278.332 23.9662 274.484C23.7536 274.253 23.6373 273.949 23.6411 273.634C23.4243 273.579 23.2087 273.519 22.9965 273.442C20.605 272.589 18.7302 270.561 17.9138 268.18C16.9671 265.432 17.2842 262.649 17.9887 259.893C18.1617 259.22 18.3589 258.49 18.209 257.794C18.0591 257.098 17.5448 256.496 17.0905 255.963C16.1334 254.837 15.1303 253.773 14.7452 252.311C14.3854 250.954 14.5019 249.539 14.7521 248.172C15.0542 246.521 15.4289 244.88 15.7702 243.236C16.1115 241.592 16.4494 239.933 16.7999 238.281C17.0974 236.883 17.4514 235.452 18.1467 234.194C18.8881 232.849 19.934 231.854 21.3511 231.238Z"
      fill="url(#paint11_linear_2851_159053)"
    />
    <path
      d="M85.6214 192.12C82.063 192.055 78.3974 193.157 75.637 195.382C73.6433 192.324 69.8117 190.579 66.0169 191.451C61.0668 192.588 58.1414 196.561 56.6125 201.069C53.3262 203.048 50.6846 206.233 49.4842 209.859C46.8322 217.878 51.1585 226.229 58.4528 229.639C57.6902 231.227 57.3383 232.98 57.4294 234.738C57.5204 236.496 58.0515 238.203 58.974 239.703C61.4035 243.621 65.9408 245.256 70.3893 244.579C72.6482 244.233 74.8033 243.443 77.0056 242.866C79.4271 242.233 81.5787 242.399 84.0463 242.487C88.8788 242.66 93.6052 240.601 96.6551 236.816C99.3613 233.457 100.317 229.075 99.6531 224.901C102.517 223.963 104.871 221.81 106.375 219.14C108.517 215.348 108.699 210.924 107.146 206.882C104.005 198.706 94.327 192.278 85.6214 192.12Z"
      fill="url(#paint12_linear_2851_159053)"
    />
    <path
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.4}
      d="M53.4097 219.754C55.6766 225.352 62.1084 226.252 67.6386 226.154C74.0957 225.836 76.7478 232.75 81.8916 235.793C82.8902 236.377 83.9868 236.548 84.9853 235.647C89.4189 230.923 88.2969 224.545 87.6132 218.532C87.1254 216.147 88.9969 214.491 91.3641 213.735C93.4834 213.274 95.3595 214.587 96.8216 215.809C100.923 210.242 101.532 202.291 98.2779 196.375C102.264 199.034 105.549 202.737 107.146 206.893C108.699 210.935 108.517 215.359 106.376 219.151C104.877 221.815 102.518 223.969 99.6535 224.912C100.317 229.086 99.3606 233.468 96.6555 236.827C93.6045 240.612 88.8781 242.671 84.0467 242.498C81.5792 242.41 79.4345 242.244 77.0061 242.877C74.8037 243.454 72.6475 244.246 70.3898 244.59C65.9401 245.267 61.4016 243.637 58.9744 239.714C58.0519 238.214 57.5209 236.507 57.4298 234.749C57.3388 232.991 57.6906 231.238 58.4532 229.65C51.1589 226.24 46.8314 217.889 49.4847 209.87C50.2733 207.549 51.5809 205.438 53.3082 203.698C51.4552 208.832 51.3053 214.601 53.4097 219.754Z"
      fill="url(#paint13_linear_2851_159053)"
    />
    <path
      d="M97.5138 272.603C94.6311 270.2 76.2789 278.305 76.9315 285.472C77.5842 292.639 103.212 277.347 97.5138 272.603Z"
      fill="url(#paint14_linear_2851_159053)"
    />
    <path
      d="M210.812 271.119C209.261 271.608 207.586 271.521 206.095 270.874C204.603 270.226 203.397 269.062 202.696 267.596C201.996 266.129 201.85 264.459 202.285 262.894C202.719 261.328 203.705 259.972 205.061 259.075C205.544 258.753 205.891 258.263 206.035 257.701C206.18 257.139 206.111 256.543 205.843 256.028C200.531 245.797 194.636 234.853 189.752 226.806C179.374 209.706 167.018 190.563 162.643 183.826C162.141 183.052 161.457 182.412 160.651 181.963C159.845 181.513 158.941 181.267 158.018 181.246L102.609 179.97C101.553 179.946 100.509 180.194 99.5773 180.689C98.6452 181.184 97.8563 181.911 97.2864 182.799C92.0088 191.018 77.0788 216.007 67.5706 249.295C63.92 262.077 72.3489 275.16 85.4905 277.22L157.76 288.551L216.992 285.254C217.37 285.233 217.738 285.119 218.061 284.922C218.385 284.724 218.654 284.45 218.846 284.123C219.037 283.797 219.145 283.428 219.159 283.049C219.173 282.671 219.093 282.295 218.926 281.955C217.841 279.745 216.228 276.479 214.265 272.559C213.955 271.948 213.437 271.467 212.804 271.203C212.171 270.939 211.465 270.909 210.812 271.119Z"
      fill="url(#paint15_linear_2851_159053)"
    />
    <g
      style={{
        mixBlendMode: "soft-light",
      }}
    >
      <path
        d="M127.614 219.871C119.703 207.386 109.792 192.182 105.528 185.66C105.324 185.349 105.209 184.988 105.195 184.616C105.18 184.245 105.267 183.876 105.446 183.551C105.626 183.225 105.89 182.953 106.211 182.766C106.533 182.579 106.899 182.483 107.271 182.488C117.995 182.627 148.352 183.044 162.388 183.449L162.362 183.408C161.945 182.768 161.379 182.238 160.712 181.866C160.044 181.494 159.296 181.291 158.532 181.273L102.608 179.986C101.553 179.962 100.509 180.209 99.5768 180.705C98.6447 181.2 97.8557 181.927 97.2858 182.815C91.6923 191.525 75.2622 219.069 65.9224 255.368C63.4329 265.047 69.7944 274.776 79.6762 276.325L157.756 288.565L162.547 288.299C158.566 269.369 148.179 251.675 147.274 250.171C146.155 248.301 137.99 236.247 127.614 219.871Z"
        fill="white"
      />
    </g>
    <path
      d="M164.458 249.984C169.976 249.984 174.449 246.163 174.449 241.449C174.449 236.735 169.976 232.914 164.458 232.914C158.941 232.914 154.468 236.735 154.468 241.449C154.468 246.163 158.941 249.984 164.458 249.984Z"
      fill="url(#paint16_linear_2851_159053)"
    />
    <path
      d="M161.395 239.414C158.379 239.414 155.79 240.939 154.663 243.117C155.577 247.026 159.614 249.971 164.464 249.971C165.238 249.971 166.011 249.894 166.77 249.741C167.991 248.644 168.739 247.174 168.739 245.556C168.73 242.163 165.446 239.414 161.395 239.414Z"
      fill="url(#paint17_linear_2851_159053)"
    />
    <path
      d="M129.914 204.509C128.53 205.049 128.286 207.337 128.248 207.689C127.837 211.557 131.29 215.862 133.321 215.427C134.982 215.072 136.18 211.456 135.241 208.649C134.385 206.076 131.617 203.845 129.914 204.509Z"
      fill="url(#paint18_linear_2851_159053)"
    />
    <path
      d="M142.198 186.101C140.395 188.283 141.384 192.323 142.965 194.743C145.087 197.991 149.471 200.159 153.669 198.681C157.383 197.373 160.17 193.486 159.55 190.5C158.422 184.982 145.586 181.993 142.198 186.101Z"
      fill="url(#paint19_linear_2851_159053)"
    />
    <path
      d="M101.86 204.958C100.108 205.181 94.1565 205.938 92.0072 210.457C89.6769 215.359 92.8282 222.565 98.5336 225.162C104.53 227.892 111.794 224.827 114.529 219.536C115.983 216.722 116.596 212.424 114.401 209.05C110.983 203.798 103.348 204.769 101.86 204.958Z"
      fill="url(#paint20_linear_2851_159053)"
    />
    <path
      d="M114.402 209.039C112.298 205.806 108.597 204.932 105.714 204.781C104.003 205.794 102.224 207.316 101.217 209.551C99.1027 214.267 100.975 220.968 106.438 225.757C109.898 224.861 112.957 222.561 114.525 219.525C115.984 216.711 116.597 212.409 114.402 209.039Z"
      fill="url(#paint21_linear_2851_159053)"
    />
    <path
      d="M128.374 206.906C128.319 207.166 128.277 207.429 128.25 207.693C127.951 210.513 129.704 213.563 131.465 214.837C133.304 210.44 130.18 207.933 128.374 206.906Z"
      fill="url(#paint22_linear_2851_159053)"
    />
    <path
      d="M157.76 193.993C157.76 190.157 153.55 187.047 148.358 187.047C145.511 187.047 142.963 187.983 141.238 189.459C141.301 191.302 142.03 193.309 142.967 194.745C145.089 197.993 149.473 200.161 153.671 198.684C154.963 198.216 156.138 197.475 157.118 196.513C157.535 195.738 157.756 194.873 157.76 193.993Z"
      fill="url(#paint23_linear_2851_159053)"
    />
    <path
      d="M88.7102 238.107C87.6494 238.247 84.0472 238.722 82.7466 241.556C81.3364 244.63 83.2436 249.15 86.6958 250.774C90.3245 252.487 94.7224 250.564 96.3816 247.246C97.2614 245.482 97.6327 242.786 96.3044 240.67C94.2311 237.377 89.6096 237.985 88.7102 238.107Z"
      fill="url(#paint24_linear_2851_159053)"
    />
    <path
      d="M96.2992 240.67C95.0308 238.642 92.7858 238.094 91.0412 238C90.0034 238.635 88.9288 239.589 88.3222 240.996C87.0423 243.954 88.1758 248.156 91.4828 251.16C93.5745 250.598 95.4274 249.155 96.3776 247.252C97.2562 245.482 97.6275 242.785 96.2992 240.67Z"
      fill="url(#paint25_linear_2851_159053)"
    />
    <path
      d="M267.564 98.396L204.953 97.9351V83.4867C204.953 83.1641 205.019 82.845 205.147 82.5488C205.274 82.2526 205.461 81.9856 205.696 81.7641C205.931 81.5427 206.208 81.3714 206.511 81.2608C206.815 81.1503 207.137 81.1027 207.459 81.121L265.324 84.4142C265.928 84.4487 266.497 84.7129 266.913 85.1527C267.329 85.5925 267.561 86.1747 267.561 86.7799L267.564 98.396Z"
      fill="url(#paint26_linear_2851_159053)"
    />
    <path
      d="M268.278 98.396L205.667 94.8331V83.4867C205.667 83.1641 205.733 82.845 205.86 82.5488C205.988 82.2526 206.175 81.9856 206.41 81.7641C206.645 81.5427 206.922 81.3714 207.225 81.2608C207.528 81.1503 207.851 81.1027 208.173 81.121L266.038 84.4142C266.642 84.4487 267.211 84.7129 267.627 85.1527C268.043 85.5925 268.274 86.1747 268.275 86.7799L268.278 98.396Z"
      fill="#D8DEE8"
    />
    <path
      d="M300.323 283.849L239.213 287.242V109.68L300.323 113.073V283.849Z"
      fill="#D8DEE8"
    />
    <path
      d="M280.253 137.076C264.648 141.023 289.078 161.265 275.294 165.496C264.348 168.855 265.652 138.39 255.637 147.038C245.49 155.795 258.961 181.917 265.784 201.973C272.551 221.855 272.904 250.56 252.539 245.529C246.844 244.122 242.702 242.063 239.211 239.781V287.263L300.324 283.869V143.841C295.464 138.369 288.489 134.99 280.253 137.076Z"
      fill="url(#paint27_linear_2851_159053)"
    />
    <path
      d="M239.246 287.254L178.175 276.04V129.181L239.246 109.805V287.254Z"
      fill="url(#paint28_linear_2851_159053)"
    />
    <path
      d="M239.246 109.81L178.175 129.187L205.83 94.1484L239.246 109.81Z"
      fill="url(#paint29_linear_2851_159053)"
    />
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.3}
    >
      <path
        d="M205.83 94.125L178.174 129.163L213.933 97.9229L205.83 94.125Z"
        fill="#E6E6E6"
      />
    </g>
    <path
      d="M178.175 241.641V276.034L239.245 287.249V239.819C228.827 233.22 223.946 225.761 206.992 224.115C195.933 223.042 185.465 232.511 178.175 241.641Z"
      fill="#D8DEE8"
    />
    <path
      d="M193.179 190.922V190.985L202.554 194.57V199.538L193.179 203.722V203.784L208.494 203.698V209.012L184.381 208.656V203.444L198.013 197.231V197.168L184.381 191.825V186.494L208.494 184.586V190.033L193.179 190.922Z"
      fill="url(#paint30_linear_2851_159053)"
    />
    <path
      d="M208.494 179.067L184.381 181.487V175.887L208.494 172.891V179.067Z"
      fill="url(#paint31_linear_2851_159053)"
    />
    <path
      d="M184.381 165.647L203.46 162.452V152.482L208.494 151.445V167.388L184.381 170.891V165.647Z"
      fill="url(#paint32_linear_2851_159053)"
    />
    <path
      d="M184.381 147.534L194.15 145.148V145.086L184.381 140.851V134.951L195.359 139.833L208.494 127.445V133.948L196.579 144.486V144.55L208.494 141.639V147.251L184.381 152.62V147.534Z"
      fill="url(#paint33_linear_2851_159053)"
    />
    <path
      d="M205.83 94.1484L268.279 96.9565L300.323 113.091L239.246 109.81L205.83 94.1484Z"
      fill="url(#paint34_linear_2851_159053)"
    />
    <path
      d="M239.246 109.81L205.83 94.1484L210.509 100.481L239.246 109.81Z"
      fill="url(#paint35_linear_2851_159053)"
    />
    <path
      d="M179.771 247.723C179.771 263.458 169.827 271.956 157.559 271.956C145.292 271.956 135.348 263.458 135.348 247.723C135.348 231.989 145.292 214.977 157.559 214.977C169.827 214.977 179.771 231.989 179.771 247.723Z"
      fill="#D8DEE8"
    />
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.6}
    >
      <path
        d="M165.634 255.997C162.932 254.969 162.956 251.668 157.455 245.097C152.356 239.007 149.357 238.285 147.456 235.56C145.428 232.65 144.719 227.548 148.872 217.922C140.923 223.46 135.349 235.955 135.349 247.735C135.349 263.469 145.293 271.967 157.561 271.967C169.828 271.967 179.772 263.469 179.772 247.735C179.772 247.711 179.772 247.688 179.772 247.663C172.255 255.757 168.101 256.936 165.634 255.997Z"
        fill="url(#paint36_linear_2851_159053)"
      />
    </g>
    <path
      d="M134.16 247.723C134.16 263.458 124.216 271.956 111.949 271.956C99.6814 271.956 89.7373 263.458 89.7373 247.723C89.7373 231.989 99.6814 214.977 111.949 214.977C124.216 214.977 134.16 231.989 134.16 247.723Z"
      fill="#D8DEE8"
    />
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.6}
    >
      <path
        d="M120.028 255.997C117.325 254.969 117.349 251.668 111.848 245.097C106.749 239.007 103.75 238.285 101.849 235.56C99.8212 232.65 99.112 227.548 103.265 217.922C95.3161 223.46 89.7422 235.955 89.7422 247.735C89.7422 263.469 99.6862 271.967 111.954 271.967C124.221 271.967 134.165 263.469 134.165 247.735C134.165 247.711 134.165 247.688 134.165 247.663C126.65 255.757 122.495 256.936 120.028 255.997Z"
        fill="url(#paint37_linear_2851_159053)"
      />
    </g>
    <path
      d="M53.1665 259.318L98.6066 282.426L211.961 261.157L163.095 258.242L53.1665 259.318Z"
      fill="#D8DEE8"
    />
    <path
      d="M200.386 252.497C200.386 268.231 190.442 276.729 178.175 276.729C165.907 276.729 155.964 268.231 155.964 252.497C155.964 236.762 165.908 219.75 178.176 219.75C190.443 219.75 200.386 236.762 200.386 252.497Z"
      fill="white"
    />
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.6}
    >
      <path
        d="M186.251 260.762C183.548 259.734 183.572 256.433 178.071 249.863C172.972 243.773 169.973 243.051 168.073 240.325C166.044 237.416 165.335 232.314 169.489 222.688C161.539 228.225 155.965 240.721 155.965 252.5C155.965 268.235 165.909 276.733 178.177 276.733C190.444 276.733 200.388 268.235 200.388 252.5C200.388 252.476 200.388 252.453 200.388 252.429C192.872 260.523 188.712 261.701 186.251 260.762Z"
        fill="url(#paint38_linear_2851_159053)"
      />
    </g>
    <path
      d="M154.775 252.497C154.775 268.231 144.831 276.729 132.564 276.729C120.296 276.729 110.352 268.231 110.352 252.497C110.352 236.762 120.296 219.75 132.564 219.75C144.831 219.75 154.775 236.762 154.775 252.497Z"
      fill="white"
    />
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.6}
    >
      <path
        d="M140.638 260.762C137.935 259.734 137.959 256.433 132.458 249.863C127.359 243.773 124.36 243.051 122.46 240.325C120.432 237.416 119.722 232.314 123.876 222.688C115.926 228.225 110.353 240.721 110.353 252.5C110.353 268.235 120.297 276.733 132.564 276.733C144.832 276.733 154.776 268.235 154.776 252.5C154.776 252.476 154.776 252.453 154.776 252.429C147.26 260.523 143.105 261.701 140.638 260.762Z"
        fill="url(#paint39_linear_2851_159053)"
      />
    </g>
    <path
      d="M109.166 252.497C109.166 268.231 99.2222 276.729 86.9547 276.729C74.6872 276.729 64.7432 268.231 64.7432 252.497C64.7432 236.762 74.6872 219.75 86.9547 219.75C99.2222 219.75 109.166 236.762 109.166 252.497Z"
      fill="white"
    />
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.6}
    >
      <path
        d="M95.03 260.762C92.3272 259.734 92.3514 256.433 86.8501 249.863C81.7512 243.773 78.7521 243.051 76.8518 240.325C74.8236 237.416 74.1145 232.314 78.2678 222.688C70.3186 228.225 64.7446 240.721 64.7446 252.5C64.7446 268.235 74.6887 276.733 86.9562 276.733C99.2237 276.733 109.168 268.235 109.168 252.5C109.168 252.476 109.168 252.453 109.168 252.429C101.656 260.523 97.4964 261.701 95.03 260.762Z"
        fill="url(#paint40_linear_2851_159053)"
      />
    </g>
    <path
      d="M98.6431 298.854L63.1285 289.74C61.4021 289.276 59.8355 288.349 58.598 287.06C57.3606 285.77 56.4993 284.167 56.1075 282.424L51.5564 261.086C51.5083 260.857 51.5154 260.62 51.5769 260.394C51.6384 260.169 51.7527 259.961 51.9103 259.788C52.0679 259.615 52.2643 259.482 52.4834 259.399C52.7025 259.317 52.938 259.288 53.1706 259.314L87.8181 263.312L87.8769 263.321L88.3831 263.379L89.3643 266.774L98.6431 298.854Z"
      fill="url(#paint41_linear_2851_159053)"
    />
    <path
      d="M212.481 262.537L205.341 284.106L204.012 288.117C203.511 289.628 202.57 290.954 201.308 291.925C200.047 292.897 198.524 293.468 196.934 293.566L101.979 299.392C100.108 299.508 98.259 298.937 96.7805 297.785C95.3021 296.633 94.2962 294.98 93.9525 293.139L88.7234 265.211L88.3774 263.38L211.343 260.992C211.531 260.988 211.718 261.029 211.886 261.112C212.055 261.195 212.202 261.318 212.313 261.469C212.425 261.62 212.499 261.797 212.528 261.982C212.557 262.168 212.541 262.358 212.481 262.537Z"
      fill="#D8DEE8"
    />
    <path
      d="M201.978 228.727C194.803 228.176 195.174 226.456 188.058 226.167C183.621 225.987 174.171 225.603 167.392 230.604C156.733 238.466 158.209 255.555 158.597 260.045C159.811 274.103 167.891 290.539 182.08 296.654C188.687 299.501 195.902 299.803 202.234 300.067C208.702 300.338 214.154 300.565 219.654 298.872C238.666 293.021 244.899 268.337 245.188 267.127C245.995 263.748 250.695 243.986 239.381 232.904C235.036 228.65 229.836 227.151 228.707 226.846C219.125 224.258 215.028 229.73 201.978 228.727Z"
      fill="url(#paint42_linear_2851_159053)"
    />
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.3}
    >
      <path
        d="M197.795 293.336C196.156 289.012 197.046 285.028 193.866 282.413C190.401 279.562 186.519 281.971 181.911 280.962C170.881 278.542 160.118 257.509 167.309 243.755C171.673 235.407 181.209 232.501 182.423 232.149C194.004 228.795 202.594 235.501 207.187 231.04C207.855 230.38 208.364 229.577 208.676 228.692C206.449 228.9 204.208 228.915 201.979 228.735C194.805 228.184 195.176 226.464 188.059 226.175C183.622 225.995 174.173 225.611 167.394 230.612C156.735 238.474 158.211 255.562 158.598 260.053C159.812 274.111 167.892 290.547 182.082 296.662C188.689 299.509 195.904 299.811 202.235 300.075L202.812 300.099C200.058 297.798 198.594 295.447 197.795 293.336Z"
        fill="url(#paint43_linear_2851_159053)"
      />
    </g>
    <path
      d="M204.97 231.206C201.545 229.754 201.469 224.126 201.469 223.867C201.469 220.037 203.703 216.326 206.427 214.138C209.349 211.786 212.101 211.777 213.515 208.762C214.155 207.397 214.266 205.964 214.796 205.946C215.411 205.924 215.917 207.823 216.333 209.273C218.86 218.079 220.193 217.827 219.834 220.367C219.23 224.659 215.161 227.281 212.661 228.901C210.208 230.48 207.453 232.26 204.97 231.206Z"
      fill="url(#paint44_linear_2851_159053)"
    />
    <path
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.3}
      d="M216.324 209.275C215.931 207.905 215.457 206.135 214.888 205.961C215.402 207.852 215.19 209.187 214.787 210.128C213.928 212.145 212.331 212.116 211.115 214.737C209.972 217.198 210.957 218.139 209.749 220.54C208.662 222.698 207.396 222.871 206.248 224.465C205.325 225.748 204.585 227.799 205.08 231.251C207.536 232.205 210.244 230.458 212.652 228.903C215.152 227.29 219.224 224.66 219.825 220.369C220.184 217.825 218.852 218.081 216.324 209.275Z"
      fill="url(#paint45_linear_2851_159053)"
    />
    <path
      d="M204.88 231.888C205.132 231.889 205.375 231.798 205.563 231.632C207.231 230.039 201.091 222.414 199.073 217.892C198.866 217.397 198.544 216.958 198.134 216.612C197.037 215.708 195.581 215.805 194.889 215.843C193.955 215.902 192.619 215.986 192.412 216.612C192.182 217.303 193.488 218.217 196.255 220.452C198.829 222.532 200.117 223.573 200.44 223.951C203.451 227.482 203.288 231.848 204.88 231.888Z"
      fill="url(#paint46_linear_2851_159053)"
    />
    <path
      d="M195.574 216.785C197.021 216.879 197.743 217.545 197.965 217.297C198.065 217.181 198.04 216.916 197.666 216.295C196.653 215.735 195.489 215.808 194.891 215.846C193.957 215.905 192.621 215.989 192.415 216.614C192.403 216.65 192.396 216.686 192.393 216.723C192.502 216.817 192.624 216.895 192.756 216.953C193.545 217.305 194.084 216.684 195.574 216.785Z"
      fill="#D8DEE8"
    />
    <path
      d="M207.276 233.169C207.276 233.169 221.367 225.404 231.444 230.353C241.52 235.302 244.594 244.519 243.143 260.05C241.691 275.582 232.639 297.001 210.009 298.708C210.009 298.708 216.841 283.091 216.499 266.963C216.156 250.834 213.934 236.07 207.276 233.169Z"
      fill="url(#paint47_linear_2851_159053)"
    />
    <path
      d="M218.545 263.638C219.966 259.512 221.633 255.695 222.986 255.873C223.749 255.973 224.225 257.322 224.267 258.263C224.364 260.485 222.138 262.862 218.545 263.638Z"
      fill="url(#paint48_linear_2851_159053)"
    />
    <g
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.3}
    >
      <path
        d="M227.168 258.079C229.013 253.642 232.554 253.065 233.487 247.838C234.17 244.015 233.32 238.467 229.729 235.721C225.839 232.745 222.058 235.606 215.554 232.99C214.296 232.482 213.097 231.839 211.979 231.07C210.366 231.664 208.795 232.362 207.273 233.162C213.935 236.063 216.152 250.826 216.498 266.955C216.844 283.083 210.009 298.7 210.009 298.7C218.984 298.023 225.824 294.244 230.9 288.963C232.289 286.526 232.801 284.304 232.808 282.316C232.836 273.798 223.63 266.601 227.168 258.079Z"
        fill="url(#paint49_linear_2851_159053)"
      />
    </g>
    <mask
      id="mask0_2851_159053"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={207}
      y={228}
      width={56}
      height={72}
    >
      <path
        d="M207.278 233.153C207.278 233.153 221.368 225.388 231.445 230.337C241.522 235.286 259.58 233.622 262.144 250.093C264.542 265.507 262.315 304.025 210.011 298.692C210.011 298.692 216.843 283.076 216.5 266.947C216.158 250.819 213.936 236.055 207.278 233.153Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2851_159053)">
      <path
        d="M205.735 294.342C212.287 281.252 219.676 272.954 225.377 267.718C228.792 264.581 230.903 263.185 235.539 259.014C241.814 253.373 247.616 247.226 252.885 240.637C253.102 240.366 253.379 240.149 253.695 240.005C254.01 239.861 254.355 239.793 254.702 239.806C255.049 239.82 255.387 239.915 255.691 240.084C255.994 240.253 256.253 240.49 256.447 240.778C259.007 244.58 262.719 251.647 262.252 260.779C261.906 267.432 259.294 275.092 252.19 283.079C230.486 307.477 205.735 294.342 205.735 294.342Z"
        fill="url(#paint50_linear_2851_159053)"
      />
      <path
        d="M256.448 240.78C256.177 240.378 255.783 240.077 255.324 239.922C254.866 239.766 254.369 239.766 253.911 239.92C255.659 250.444 257.255 273.782 240.401 286.409C227.372 296.173 214.317 296.065 205.749 294.309L205.731 294.346C205.731 294.346 230.482 307.482 252.186 283.081C259.29 275.094 261.906 267.435 262.247 260.781C262.719 251.649 259.007 244.578 256.448 240.78Z"
        fill="url(#paint51_linear_2851_159053)"
      />
      <path
        d="M227.17 270.37C226.602 269.227 231.17 264.378 234.343 265.592C235.167 265.908 236.234 266.763 236.136 267.639C235.882 269.926 227.812 271.664 227.17 270.37Z"
        fill="url(#paint52_linear_2851_159053)"
      />
    </g>
    <path
      d="M230.767 172.569C230.767 172.569 230.004 169.992 228.691 169.919C227.378 169.845 222.444 167.814 223.025 168.975C223.606 170.137 225.646 170.588 225.646 170.588C225.646 170.588 224.659 172.866 226.869 173.434C229.08 174.002 233.716 176.67 230.767 172.569Z"
      fill="url(#paint53_linear_2851_159053)"
    />
    <path
      d="M231.039 171.711L229.22 175.755C230.916 178.923 233.055 181.833 235.572 184.398C239.031 187.888 243.125 190.687 247.635 192.642C256.44 196.418 271.743 198.785 275.115 192.689C278.123 187.251 270.252 177.565 268.811 175.83C266.702 177.273 269.052 173.906 258.414 180.426C257.045 181.264 252.777 181.715 247.91 181.048C238.9 179.813 233.053 173.943 231.039 171.711Z"
      fill="url(#paint54_linear_2851_159053)"
    />
    <path
      d="M268.518 292.477C268.518 292.477 268.876 294.666 268.829 295.44C268.783 296.215 260.146 296.616 260.059 296.033C259.973 295.449 261.487 294.523 262.553 294.196C263.62 293.869 266.741 292.996 266.741 292.996L268.518 292.477Z"
      fill="url(#paint55_linear_2851_159053)"
    />
    <path
      d="M266.422 290.315L266.697 293.034C266.697 293.034 267.701 293.696 268.585 293.184L269.028 290.227L266.422 290.315Z"
      fill="url(#paint56_linear_2851_159053)"
    />
    <path
      d="M283.929 296.023C283.929 296.023 284.287 298.213 284.242 298.987C284.197 299.761 275.558 300.162 275.471 299.579C275.385 298.996 276.899 298.07 277.965 297.743C279.032 297.415 282.152 296.543 282.152 296.543L283.929 296.023Z"
      fill="url(#paint57_linear_2851_159053)"
    />
    <path
      d="M281.833 293.847L282.109 296.565C282.109 296.565 283.112 297.227 283.997 296.716L284.439 293.758L281.833 293.847Z"
      fill="url(#paint58_linear_2851_159053)"
    />
    <path
      d="M267.99 219.356L264.992 225.424C264.185 236.967 263.791 249.52 264.047 262.944C264.237 272.932 264.772 282.393 265.539 291.274L269.6 291.087C271.56 287.205 273.866 281.323 274.081 274.062C274.302 266.488 272.005 265.484 272.15 258.231C272.34 248.832 276.283 246.325 278.385 235.019C279.192 230.695 279.73 225.048 279.086 218.195L267.99 219.356Z"
      fill="url(#paint59_linear_2851_159053)"
    />
    <path
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.3}
      d="M279.088 218.203L273.092 218.828C269.496 225.082 268.492 231.593 269.122 234.472C270.015 238.547 272.377 239.851 272.247 243.942C272.119 247.988 269.732 249.038 268.218 253.809C267.17 257.111 267.447 260.146 268.043 266.087C269.243 278.05 272.134 281.707 269.713 285.63C269.021 286.744 267.785 288.071 265.356 289.087C265.416 289.821 265.472 290.556 265.537 291.282L269.599 291.096C271.559 287.213 273.865 281.332 274.079 274.071C274.301 266.497 272.004 265.491 272.149 258.24C272.338 248.841 276.282 246.334 278.384 235.029C279.19 230.702 279.732 225.055 279.088 218.203Z"
      fill="url(#paint60_linear_2851_159053)"
    />
    <path
      d="M273.361 222.993C275.235 233.207 276.891 244.138 278.182 255.73C279.742 269.726 280.574 282.923 280.901 295.174L285.359 295.3C291.214 283.725 291.572 275.877 290.728 270.768C290.403 268.799 289.519 264.939 289.317 259.169C289.177 255.148 289.433 252.086 289.754 248.407C290.458 240.341 291.311 238.578 291.433 232.407C291.549 226.616 291.573 223.686 289.918 221.976C285.56 217.469 274.222 222.595 273.361 222.993Z"
      fill="url(#paint61_linear_2851_159053)"
    />
    <path
      d="M263.857 227.497C262.433 225.136 264.221 222.86 264.628 218.387C265.09 213.317 263.135 212.474 261.204 204.073C259.919 198.487 258.686 193.126 260.158 186.845C260.864 183.837 262.061 178.733 266.287 176.269C268.467 175.001 271.445 175.123 277.312 175.431C284.567 175.81 288.195 175.999 290.9 177.423C291.296 177.632 299.526 182.105 300.092 189.398C300.669 196.819 292.581 197.817 291.502 207.719C291.018 212.162 291.881 218.995 292.441 223.428C292.978 227.683 293.348 228.773 292.507 230.062C290.481 233.173 284.28 232.282 271.878 230.512C267.013 229.812 264.919 229.258 263.857 227.497Z"
      fill="url(#paint62_linear_2851_159053)"
    />
    <path
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.3}
      d="M282.727 206.124C283.252 208.053 285.138 209.493 286.45 214.19C286.734 215.202 288.757 222.417 286.134 224.193C284.622 225.218 283.263 223.271 279.592 223.022C273.23 222.582 270.199 227.925 266.043 226.466C265.323 226.212 264.37 225.673 263.42 224.432C263.231 225.55 263.263 226.52 263.856 227.503C264.918 229.265 267.012 229.818 271.877 230.513C284.279 232.283 290.48 233.163 292.506 230.062C293.347 228.774 292.977 227.684 292.439 223.429C291.88 218.997 291.017 212.163 291.501 207.72C292.58 197.823 300.668 196.825 300.091 189.398C299.937 187.414 299.207 185.648 298.23 184.109C287.97 188.26 281.078 200.063 282.727 206.124Z"
      fill="url(#paint63_linear_2851_159053)"
    />
    <path
      d="M295.686 220.72L293.634 222.03C293.265 221.605 292.411 220.746 291.08 220.454C289.581 220.124 287.851 220.596 287.815 221.076C287.783 221.459 288.832 221.847 288.995 221.907C290.305 222.389 291.277 221.988 291.806 222.515C291.993 222.7 292.227 223.047 292.763 223.368C292.881 223.439 293.003 223.502 293.13 223.557C294.004 223.897 295.504 222.376 295.632 222.243L295.686 220.72Z"
      fill="url(#paint64_linear_2851_159053)"
    />
    <path
      d="M289.621 193.804C288.136 190.25 287.108 184.809 289.453 176.727C304.904 182.812 312.79 197.237 309.712 208.676C307.176 218.104 297.945 222.696 295.886 223.662L294.448 220.393C296.834 217.743 299.522 213.811 299.142 209.331C298.616 203.121 292.628 200.995 289.621 193.804Z"
      fill="url(#paint65_linear_2851_159053)"
    />
    <path
      d="M277.691 168.109C277.691 168.109 277.813 170.811 278.653 172.834C279.411 174.662 283.4 175.811 284.208 176.22C285.016 176.629 282.693 177.822 280.36 178.056C278.028 178.29 271.366 177.761 270.163 176.877C268.959 175.993 271.128 176.06 272.794 175.448C274.46 174.836 275.422 171.582 275.129 170.105C274.836 168.628 277.691 168.109 277.691 168.109Z"
      fill="url(#paint66_linear_2851_159053)"
    />
    <path
      d="M274 167.139C272.79 165.95 272.617 165.812 272.489 165.4C271.959 163.751 273.239 161.493 274.918 160.936C275.006 160.908 276.131 160.547 276.561 161.035C276.927 161.45 276.389 162.023 276.644 162.822C276.883 163.569 277.532 163.628 278.735 164.606C279.725 165.413 280.341 165.917 280.483 166.623C280.776 168.079 279.012 170.195 277.45 170.133C276.889 170.112 276.737 169.825 274 167.139Z"
      fill="url(#paint67_linear_2851_159053)"
    />
    <path
      d="M277.299 170.349C277.299 170.349 276.012 171.805 274.052 171.324C272.297 170.893 272.744 166.867 274.029 166.224C274.458 166.009 275.236 166.027 275.822 166.309C276.168 166.476 276.263 166.649 276.834 166.91C277.36 167.151 277.526 167.118 277.669 167.287C277.847 167.499 277.923 167.956 277.368 169.057C277.768 168.775 278.198 168.711 278.462 168.903C278.552 168.974 278.619 169.068 278.658 169.175C278.823 169.677 278.306 170.184 277.299 170.349Z"
      fill="url(#paint68_linear_2851_159053)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2851_159053"
        x1={117.481}
        y1={33.4954}
        x2={114.239}
        y2={257.369}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D8DEE8" />
        <stop offset={0.49} stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2851_159053"
        x1={28.1935}
        y1={268.456}
        x2={80.137}
        y2={268.456}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4FC3BA" />
        <stop offset={1} stopColor="#A1F094" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2851_159053"
        x1={76.4856}
        y1={245.51}
        x2={40.0866}
        y2={281.304}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24ABB5" />
        <stop offset={1} stopColor="#40CF9F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2851_159053"
        x1={14.0351}
        y1={214.113}
        x2={51.6683}
        y2={253.797}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4FC3BA" />
        <stop offset={1} stopColor="#A1F094" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2851_159053"
        x1={29.4048}
        y1={229.846}
        x2={125.718}
        y2={229.846}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4FC3BA" />
        <stop offset={1} stopColor="#A1F094" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2851_159053"
        x1={115.444}
        y1={218.666}
        x2={65.0189}
        y2={176.725}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24ABB5" />
        <stop offset={1} stopColor="#40CF9F" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2851_159053"
        x1={100.62}
        y1={274.721}
        x2={25.1104}
        y2={211.917}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24ABB5" />
        <stop offset={1} stopColor="#40CF9F" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2851_159053"
        x1={81.0174}
        y1={277.007}
        x2={-17.0264}
        y2={9.70438}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24ABB5" />
        <stop offset={1} stopColor="#40CF9F" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_2851_159053"
        x1={95.08}
        y1={223.017}
        x2={129.296}
        y2={231.121}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4FC3BA" />
        <stop offset={1} stopColor="#A1F094" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_2851_159053"
        x1={126.161}
        y1={240.542}
        x2={97.4976}
        y2={216.701}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24ABB5" />
        <stop offset={1} stopColor="#40CF9F" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_2851_159053"
        x1={71.8275}
        y1={245.615}
        x2={8.85822}
        y2={252.302}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4FC3BA" />
        <stop offset={0.84} stopColor="#A1F094" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_2851_159053"
        x1={50.6643}
        y1={269.912}
        x2={13.9957}
        y2={239.413}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24ABB5" />
        <stop offset={1} stopColor="#40CF9F" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_2851_159053"
        x1={70.5}
        y1={235.657}
        x2={85.2632}
        y2={194.172}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4FC3BA" />
        <stop offset={1} stopColor="#A1F094" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_2851_159053"
        x1={98.2837}
        y1={235.338}
        x2={56.2776}
        y2={200.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24ABB5" />
        <stop offset={1} stopColor="#40CF9F" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_2851_159053"
        x1={100.589}
        y1={263.919}
        x2={68.4784}
        y2={303.865}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24ABB5" />
        <stop offset={1} stopColor="#40CF9F" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_2851_159053"
        x1={66.7162}
        y1={234.26}
        x2={219.162}
        y2={234.26}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_2851_159053"
        x1={170.742}
        y1={228.798}
        x2={144.667}
        y2={281.365}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_2851_159053"
        x1={168.338}
        y1={194.398}
        x2={160.671}
        y2={252.323}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_2851_159053"
        x1={136.281}
        y1={205.658}
        x2={116.332}
        y2={224.426}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_2851_159053"
        x1={150.805}
        y1={186.783}
        x2={143.905}
        y2={251.229}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_2851_159053"
        x1={91.2335}
        y1={215.472}
        x2={115.839}
        y2={215.472}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_2851_159053"
        x1={135.469}
        y1={238.811}
        x2={104.394}
        y2={211.939}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_2851_159053"
        x1={138.762}
        y1={200.951}
        x2={129.045}
        y2={211.87}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_2851_159053"
        x1={163.725}
        y1={163.915}
        x2={151.838}
        y2={187.706}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_2851_159053"
        x1={82.2784}
        y1={244.698}
        x2={97.1703}
        y2={244.698}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_2851_159053"
        x1={109.366}
        y1={259.126}
        x2={90.1819}
        y2={242.536}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_2851_159053"
        x1={204.955}
        y1={89.7562}
        x2={267.564}
        y2={89.7562}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_2851_159053"
        x1={251.919}
        y1={211.281}
        x2={293.837}
        y2={213.593}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_2851_159053"
        x1={224.727}
        y1={264.358}
        x2={172.28}
        y2={250.807}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1454B8" />
        <stop offset={1} stopColor="#46ACF9" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_2851_159053"
        x1={192.916}
        y1={112.061}
        x2={213.009}
        y2={120.721}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_2851_159053"
        x1={184.381}
        y1={196.798}
        x2={208.495}
        y2={196.798}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_2851_159053"
        x1={184.38}
        y1={177.191}
        x2={208.494}
        y2={177.191}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_2851_159053"
        x1={184.381}
        y1={161.166}
        x2={208.495}
        y2={161.166}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_2851_159053"
        x1={184.381}
        y1={140.034}
        x2={208.495}
        y2={140.034}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_2851_159053"
        x1={251.857}
        y1={115.057}
        x2={256.142}
        y2={74.816}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_2851_159053"
        x1={205.83}
        y1={101.979}
        x2={239.245}
        y2={101.979}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_2851_159053"
        x1={147.58}
        y1={273.954}
        x2={175.722}
        y2={197.644}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_2851_159053"
        x1={101.973}
        y1={273.954}
        x2={130.116}
        y2={197.644}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_2851_159053"
        x1={168.196}
        y1={278.719}
        x2={196.338}
        y2={202.409}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_2851_159053"
        x1={122.584}
        y1={278.719}
        x2={150.727}
        y2={202.409}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_2851_159053"
        x1={76.9752}
        y1={278.719}
        x2={105.118}
        y2={202.409}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_2851_159053"
        x1={65.1522}
        y1={267.486}
        x2={112.805}
        y2={311.831}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_2851_159053"
        x1={188.662}
        y1={220.116}
        x2={217.32}
        y2={293.519}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9085" />
        <stop offset={1} stopColor="#FB6FBB" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_2851_159053"
        x1={175.145}
        y1={225.403}
        x2={203.804}
        y2={298.806}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9085" />
        <stop offset={1} stopColor="#FB6FBB" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_2851_159053"
        x1={201.466}
        y1={218.735}
        x2={219.884}
        y2={218.735}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AB316D" />
        <stop offset={1} stopColor="#792D3D" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_2851_159053"
        x1={204.922}
        y1={218.741}
        x2={219.884}
        y2={218.741}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AB316D" />
        <stop offset={1} stopColor="#792D3D" />
      </linearGradient>
      <linearGradient
        id="paint46_linear_2851_159053"
        x1={192.388}
        y1={223.854}
        x2={205.848}
        y2={223.854}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBEFF2" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint47_linear_2851_159053"
        x1={229.012}
        y1={283.047}
        x2={210.595}
        y2={205.231}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_2851_159053"
        x1={220.523}
        y1={254.972}
        x2={225.639}
        y2={289.279}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AB316D" />
        <stop offset={1} stopColor="#792D3D" />
      </linearGradient>
      <linearGradient
        id="paint49_linear_2851_159053"
        x1={224.92}
        y1={284.007}
        x2={206.503}
        y2={206.19}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint50_linear_2851_159053"
        x1={262.36}
        y1={315.374}
        x2={228.582}
        y2={263.65}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint51_linear_2851_159053"
        x1={205.736}
        y1={268.841}
        x2={262.291}
        y2={268.841}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9085" />
        <stop offset={1} stopColor="#FB6FBB" />
      </linearGradient>
      <linearGradient
        id="paint52_linear_2851_159053"
        x1={229.365}
        y1={253.644}
        x2={234.483}
        y2={287.949}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AB316D" />
        <stop offset={1} stopColor="#792D3D" />
      </linearGradient>
      <linearGradient
        id="paint53_linear_2851_159053"
        x1={223.071}
        y1={159.632}
        x2={229.451}
        y2={177.441}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint54_linear_2851_159053"
        x1={198.76}
        y1={167.44}
        x2={286.61}
        y2={196.333}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint55_linear_2851_159053"
        x1={259.418}
        y1={268.925}
        x2={267.297}
        y2={309.833}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#311944" />
        <stop offset={1} stopColor="#893976" />
      </linearGradient>
      <linearGradient
        id="paint56_linear_2851_159053"
        x1={267.028}
        y1={298.027}
        x2={268.269}
        y2={284.541}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint57_linear_2851_159053"
        x1={273.619}
        y1={266.195}
        x2={281.498}
        y2={307.102}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#311944" />
        <stop offset={1} stopColor="#893976" />
      </linearGradient>
      <linearGradient
        id="paint58_linear_2851_159053"
        x1={282.633}
        y1={299.452}
        x2={283.874}
        y2={285.965}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint59_linear_2851_159053"
        x1={262.72}
        y1={253.978}
        x2={277.102}
        y2={255.13}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset={1} stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint60_linear_2851_159053"
        x1={262.72}
        y1={253.982}
        x2={277.102}
        y2={255.135}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset={1} stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint61_linear_2851_159053"
        x1={270.675}
        y1={256.675}
        x2={292.51}
        y2={258.426}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset={1} stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint62_linear_2851_159053"
        x1={314.354}
        y1={262.781}
        x2={271.273}
        y2={188.945}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint63_linear_2851_159053"
        x1={314.349}
        y1={262.787}
        x2={271.268}
        y2={188.95}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint64_linear_2851_159053"
        x1={287.783}
        y1={221.63}
        x2={295.644}
        y2={222.26}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint65_linear_2851_159053"
        x1={328.781}
        y1={254.353}
        x2={285.7}
        y2={180.516}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint66_linear_2851_159053"
        x1={336.006}
        y1={109.079}
        x2={369.593}
        y2={72.5671}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
      <linearGradient
        id="paint67_linear_2851_159053"
        x1={279.888}
        y1={177.158}
        x2={275.253}
        y2={161.901}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset={1} stopColor="#3E4177" />
      </linearGradient>
      <linearGradient
        id="paint68_linear_2851_159053"
        x1={331.595}
        y1={105.024}
        x2={365.181}
        y2={68.512}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC444" />
        <stop offset={1} stopColor="#F36F56" />
      </linearGradient>
    </defs>
  </svg>
)