import React from 'react'
import './style.css'

export default function LoadingComponent() {
    return (
        <main className="main-container">
      <svg viewBox="25 25 50 50" className="spinner">
        <circle
          r="20"
          cx="50"
          cy="50"
          className="spinner-circle"
        />
      </svg>
      <p className="loading-text">Loading...</p>
    </main>
    )
}
