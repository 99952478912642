import { useEffect, useState } from 'react';
import { AuthMbg1, AuthMbg2, AuthMbg3 } from '../../assets/images';
import Slider1 from './sliderComponent/slider1';
import Slider2 from './sliderComponent/slider2';
import Slider3 from './sliderComponent/slider3';

const dataBanner = [
    { id: 1, image: AuthMbg1, component: <Slider1 /> },
    { id: 2, image: AuthMbg2, component: <Slider2 /> },
    { id: 3, image: AuthMbg3, component: <Slider3 /> },
];

const HeroPage = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex === dataBanner.length - 1 ? 0 : prevIndex + 1));
        }, 8000);

        return () => clearInterval(interval);
    }, []);

    const goToSlide = (index: number) => {
        setCurrentIndex(index);
    };

    return (
        <div className="w-full max-w-lg mx-auto flex flex-col items-center px-4 lg:px-0">
            {dataBanner.map((items, index) => (
                <div
                    key={items.id}
                    className={`transition-opacity duration-700 ease-in-out ${
                        index === currentIndex ? 'opacity-100' : 'opacity-0'
                    } ${index === currentIndex ? 'block' : 'hidden'}`}
                >
                    {/* Image Wrapper */}
                    <div className="p-4 lg:p-6 w-full max-w-md mx-auto">
                        <img src={items.image} alt="login" className="w-full h-auto object-cover rounded-lg bg-white" />
                    </div>
                    {/* Optional Component */}
                    <div className="mt-4">{items.component}</div>
                </div>
            ))}
            {/* Indicator Dots */}
            <div className="flex justify-center mt-4 lg:mt-6">
                {dataBanner.map((_, idx) => (
                    <button
                        key={idx}
                        className={`h-3 w-3 rounded-full mx-1 ${
                            idx === currentIndex ? 'bg-custom-gradient w-[30px] lg:w-[50px]' : 'bg-slate-400'
                        }`}
                        onClick={() => goToSlide(idx)}
                    ></button>
                ))}
            </div>
        </div>
    );
};

export default HeroPage;
