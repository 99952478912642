import React from 'react'
import { CateringOnIcon } from './icon'

export default function RecurringBar() {
  return (
    <div className="bg-blue-50 flex items-center p-4">
            {/* Icon Section */}
            <div className="w-12 h-12 flex-shrink-0">
                <CateringOnIcon />
            </div>

            {/* Text Section */}
            <div className="ml-4">
                <p className="font-semibold text-gray-800">
                    Pesanan katering otomatis sudah aktif
                </p>
                <p className="text-sm text-gray-600">
                    Jika ingin menonaktifkan pesanan, masuk ke halaman{" "}
                    <a
                        href="/profile/ketentuan&privasi/recurring"
                        className="text-blue-500 hover:underline"
                    >
                        pengaturan sistem
                    </a>
                </p>
            </div>
        </div>
  )
}
