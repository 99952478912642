import React from 'react'

export default function slider2() {
  return (
    <div className="flex flex-col items-center text-center mt-[20px]">
            <div className='flex gap-[0px] items-center justify-center'>
                <h1 className="text-[32px] font-inter font-semibold">
                    Mendukung
                    {" "}
                    <span className='font-bold text-[32px] bg-custom-gradient bg-clip-text text-transparent'>Pertumbuhan</span>
                    {" "}
                    &
                    {" "}
                    <span className='font-bold text-[32px] bg-custom-gradient bg-clip-text text-transparent'>Perkembangan</span>
                </h1>
            </div>
            <p className="text-[#6D7588] text-[18px] font-inter">
            Makan Bergizi Gratis untuk menunjang pertumbuhan optimal, perkembangan otak, kesehatan tulang dll
            </p>
        </div>
  )
}
