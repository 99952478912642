import React from 'react';

interface StepIndicatorVendorProps {
    steps: string[];
    activeStep: number;
    onMoveFormCompany?: (step: string) => void;
}

const StepIndicator: React.FC<StepIndicatorVendorProps> = ({ steps, activeStep, onMoveFormCompany }) => (
    <div className="flex justify-center">
        <div className="w-[365px] h-[35px] flex justify-center items-center mb-2">
            {steps.map((step, index) => (
                <React.Fragment key={index}>
                    <button
                        className={`rounded-full ${index < activeStep ? 'bg-custom-gradient text-white' : 'border'} w-[35px] h-[35px] flex justify-center items-center ${index !== 0 ? 'mx-[15px]' : 'mr-[15px]'} outline-none`}
                        // onClick={() => onMoveFormCompany(step)}
                    >
                        {index + 1}
                    </button>
                    {index !== steps.length - 1 && (
                        <div
                            className={`w-[45px] h-[3.1px] ${index < activeStep ? 'bg-custom-gradient' : 'bg-[#C7C9D9]'}`}
                        ></div>
                    )}
                </React.Fragment>
            ))}
        </div>
    </div>
);

export default StepIndicator;
