import React from 'react'
import { OtomaticOrderIcon } from './icon'
import { useNavigate } from 'react-router-dom'

export default function OtomaticOrderNotFound() {
    const navigate = useNavigate()
    const handleNavigate = () => {
        navigate('/')
    }
  return (
    <div className="flex flex-col items-center justify-center bg-white p-8">
    {/* Icon Section */}
    <OtomaticOrderIcon/>
    {/* Text Section */}
    <div className="text-center mt-6">
      <p className="text-xl font-semibold text-gray-800">Yuk, mulai berbelanja!</p>
      <p className="text-sm text-gray-600 mt-2">
        belum ada riwayat pesanan nih.
        <br />
        ayo mulai pesan makanan bergizi sekarang
      </p>
    </div>

    {/* Button Section */}
    <button onClick={handleNavigate} className="mt-8 px-6 py-3 bg-blue-600 text-white rounded-full shadow-lg">
      Kembali ke Beranda
    </button>
  </div>
  )
}
