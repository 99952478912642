import React, { useState } from 'react'
import { Switch } from '@headlessui/react'; 
interface recurringProps {
    schoolName: string,
    schoolId: string
    recurring: boolean
}

export default function RecurringCard({schoolName, schoolId, recurring}: recurringProps) {
    const [enabled, setEnabled] = useState(recurring); 
    const handleToggle = async (newState: boolean) => {
        if (recurring) {
            console.log('hit endpoint here');
            
        //   try {
        //     await fetch(`https://example.com/api/schools/${schoolId}/recurring`, {
        //       method: 'POST',
        //       headers: {
        //         'Content-Type': 'application/json',
        //       },
        //       body: JSON.stringify({ recurring: newState }),
        //     });
    
        //     console.log(`Status updated to: ${newState}`);
        //     setEnabled(newState); // Update state only if request succeeds
        //   } catch (error) {
        //     console.error('Error updating status:', error);
        //   }
        }else {
            console.log('hit endpoint fail');

        }
      };
  return (
    <div className="mt-6 flex gap-4 justify-between items-center">
    <div className='flex-1 text-justify'>
      <p className="text-md font-medium text-gray-800">
        Izinkan {schoolName} untuk melakukan pesanan katering otomatis
      </p>
      <p className="text-xs text-gray-500">
        Berikan akses agar dapat melakukan pesanan katering otomatis
      </p>
    </div>

    {/* Toggle Switch */}
    <Switch
      checked={enabled}
      onChange={(value) => handleToggle(value)}
      className={`${
        enabled ? 'bg-blue-600' : 'bg-gray-200'
      } relative inline-flex h-6 w-11 items-center rounded-full transition-colors`}
    >
      <span
        className={`${
          enabled ? 'translate-x-6' : 'translate-x-1'
        } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
      />
    </Switch>
  </div>
  )
}
