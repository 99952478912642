import React, { useState } from "react";
import { setStep } from "../../../../../../../../../redux/store/reducers/Produsen/Auth/Individual";
import { useAppDispatch } from "../../../../../../../../../redux/store";

export default function SecondStep() {
  const dispatch = useAppDispatch();
  const [nik, setNik] = useState('');
  const [npmwp, setNpwp] = useState('');
  const isDisabledBtn = nik !== '' && npmwp !== '';

  // General handle change for inputs
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(`Input Changed: ${name}, Value: ${value}`);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const file = e.target.files?.[0];
    console.log(`Uploaded File for ${field}:`, file);
  };

  const handleSubmit = () => {
    dispatch(setStep(3));
  };


  return (
    <form>
      {/* Input NIK */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 flex items-center">
          NIK <span className="text-red-500 ml-1">*</span>
        </label>
        <input
          type="tel"
          name="nik"
          placeholder="Masukkan Nomor NIK Anda"
          className="w-full h-[50px] rounded-lg border border-gray-300 p-4 mt-2 bg-white placeholder-gray-400 outline-none focus:border-blue-500"
          onChange={(e) => setNik(e.target.value)}
        />
        <p className="mt-1 text-xs text-gray-500">
          Format NIK tidak valid.
        </p>
      </div>

      {/* Unggah KTP */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 flex items-center">
          Unggah KTP <span className="text-red-500 ml-1">*</span>
        </label>
        <div className="mt-2 border-2 border-dashed border-orange-500 rounded-lg p-4 flex justify-center">
          <label className="flex flex-col items-center justify-center cursor-pointer">
            <span className="text-sm text-gray-500">Unggah Media</span>
            <span className="text-xs text-gray-400">Unggah Foto atau PDF (PNG, JPG, PDF)</span>
            <input
              type="file"
              accept=".png,.jpg,.jpeg,.pdf"
              name="ktp"
              className="hidden"
              onChange={(e) => handleFileUpload(e, "ktp")}
            />
          </label>
        </div>
        <p className="mt-1 text-xs text-gray-500">
          *Ukuran file maksimum 1 MB.
        </p>
      </div>

      {/* Input NPWP */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 flex items-center">
          NPWP <span className="text-red-500 ml-1">*</span>
        </label>
        <input
          type="tel"
          name="npwp"
          placeholder="Masukkan Nomor NPWP Anda"
          className="w-full h-[50px] rounded-lg border border-gray-300 p-4 mt-2 bg-white placeholder-gray-400 outline-none focus:border-blue-500"
          onChange={(e) => setNpwp(e.target.value)}
        />
        <p className="mt-1 text-xs text-gray-500">
          Format NPWP tidak valid.
        </p>
      </div>

      {/* Unggah NPWP */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 flex items-center">
          Unggah NPWP <span className="text-red-500 ml-1">*</span>
        </label>
        <div className="mt-2 border-2 border-dashed border-orange-500 rounded-lg p-4 flex justify-center">
          <label className="flex flex-col items-center justify-center cursor-pointer">
            <span className="text-sm text-gray-500">Unggah Media</span>
            <span className="text-xs text-gray-400">Unggah Foto atau PDF (PNG, JPG, PDF)</span>
            <input
              type="file"
              accept=".png,.jpg,.jpeg,.pdf"
              name="npwp"
              className="hidden"
              onChange={(e) => handleFileUpload(e, "npwp")}
            />
          </label>
        </div>
        <p className="mt-1 text-xs text-gray-500">
          *Ukuran file maksimum 1 MB.
        </p>
      </div>

      {/* Buttons */}
      <div className="flex justify-between mt-8 gap-3">
        <button
          type="button"
          onClick={() => dispatch(setStep(1))}
          className="w-1/3 h-[50px] border border-[#f46001] rounded-full 
                    bg-white py-3 px-3  font-roboto font-medium text-[14px] text-[#f46001] capitalize">
          Kembali
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          className={`w-2/3 ${isDisabledBtn ? 'bg-custom-gradient text-white' : 'bg-[#F3F4F8]'} 
            ${isDisabledBtn ? 'cursor-pointer' : 'cursor-not-allowed'} h-[50px] rounded-[100px] outline-none`}
        >
          Lanjut
        </button>
      </div>
    </form>
  );
}

